import {ElLoading} from 'element-plus';
import router from '@/router';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

let loadingCount = 0;
let loading;

const startLoading = () => {
    loading = ElLoading.service({
        lock: true,
        text: '拼命加载中...', // 可以自定义文字
        spinner: 'el-icon-loading', // 自定义加载图标类名
        background: 'rgba(0, 0, 0, 0.7)' // 遮罩层背景色
    });
};

const endLoading = () => {
    loading.close();
};

export const showLoading = () => {
    if (loadingCount === 0) {
        startLoading();
    }
    loadingCount += 1;
};

export const hideLoading = () => {
    if (loadingCount <= 0) {
        return;
    }
    loadingCount -= 1;
    if (loadingCount === 0) {
        endLoading();
    }
};

export const randomId = () => {
    let guid = '';
    for (let i = 1; i <= 32; i++) {
        const n = Math.floor(Math.random() * 16.0).toString(16);
        guid += n;
    }
    return guid;
};

// 回到登录
export const goLogin = () => {
    window.sessionStorage.clear();
    window.localStorage.clear();
    router.push('/');
};

// 阻止冒泡
export const debounce = (func, wait) => {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            func.apply(context, args);
        }, wait);
    };
};

// 生成区间随机整数
export const generateRandomNumber = (max, min) => {
    const randomNumber = Math.random();
    return Math.floor(randomNumber * (max - min + 1)) + min;
};

// 回到顶部
export const backTop = () => {
    const element = document.getElementById('gLayout');
    if (element) {
        element.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }
};

// 对象对比，赋值
export const mergeObjects = (obj1, obj2) => {
    if (!obj1) return;
    if (!obj2) return;
    for (let key in obj1) {
        if (obj2.hasOwnProperty(key)) {
            obj1[key] = obj2[key];
        }
    }
    return obj1;
}

// 对象数组 与 对象比较
export const mergeArrayObject = (arrObj, targetObject, keyType) => {
    if (Array.isArray(arrObj)) {
        arrObj.forEach(obj => {
            // 获取当前对象的 type
            let type = obj.type;
            // 检查 targetObject 中是否存在相同的 type
            if (targetObject.hasOwnProperty(type)) {
                // 遍历 targetObject[type] 中的每一个 key
                for (let key in targetObject) {
                    // 如果 array 对象中存在相同的 key
                    if (obj.type === key) {
                        if (keyType) {
                            obj[keyType] = targetObject[key] || '-';
                        } else {
                            obj.count = targetObject[key] || 0;
                        }
                    }
                }
            }
        });
        return arrObj
    }
}

// 删除对象数据为空的数据
export const removeEmptyValues = (obj) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            const value = obj[key];
            if (
                value === null ||
                value === undefined ||
                value === "" ||
                (Array.isArray(value) && value.length === 0) ||
                (typeof value === "object" && !Array.isArray(value) && Object.keys(value).length === 0)
            ) {
                delete obj[key];
            }
        }
    }
    return obj;
};

export const getCurrentTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1;
    const day = now.getDate();
    const hour = now.getHours();
    const minute = now.getMinutes();
    return `${year}-${month}-${day} ${hour}:${minute}`;
};

export const groupByName = (array, key) => {
    return array.reduce((accumulator, currentValue) => {
        const groupName = currentValue[key]; // 使用传入的key来获取分组名称
        const existingGroup = accumulator.find(group => group.some(item => item[key] === groupName));

        if (existingGroup) {
            existingGroup.push(currentValue);
        } else {
            accumulator.push([currentValue]);
        }

        return accumulator;
    }, []); // 初始值是一个空数组
}

export const downloadWithBlob = (url, name, curParams) => {
    const {pageSize, pageNum, ...newObj} = curParams;
    showLoading()
    const options = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: window.sessionStorage.getItem('token')
        },
        method: 'post',
        responseType: 'blob',
        body: JSON.stringify(curParams)
    };
    fetch(url, options).then(res => res.blob().then(blob => {
        FileSaver.saveAs(blob, name);
        hideLoading()
    }));
};


export const filterFirst = (arr) => {
    let farr = []
    arr.forEach(item => {
        farr.push(item[1])
    })
    return [...new Set(farr)]
}

export const flattenArray = (arr) => {
    return arr.reduce((acc, val) =>
            Array.isArray(val) ? acc.concat(flattenArray(val)) : acc.concat(val),
        []);
}

export const downloadPDF = async (content, name, callback, isHide, type) => {
    if (!isHide) {
        showLoading()
    }
    if (content) {
        /*
        *
        A4: 210mm x 297mm (8.27in x 11.69in)
	    A5: 148mm x 210mm (5.83in x 8.27in)
	    A6: 105mm x 148mm (4.13in x 5.83in)
        B5: 176mm x 250mm (6.93in x 9.84in)
	*/
        const pdfWidth = 105;
        const pdfHeight = 148;

        // 留白设置
        const marginLeft = 10; // 左右留白
        const marginTop = 10;  // 上下留白
        const usableWidth = pdfWidth - 2 * marginLeft;
        const usableHeight = pdfHeight - 2 * marginTop;

        if (type) {
            const ses = content.querySelectorAll('.export_pageh')
            const pdf = new jsPDF('p', 'mm', 'a5');
            for (let i = 0; i < ses.length; i++) {
                const canvas = await html2canvas(ses[i]);
                const imgData = canvas.toDataURL('image/png');

                // canvas 的宽高
                const canvasWidth = canvas.width;
                const canvasHeight = canvas.height;

                // 计算图片在PDF页面上的宽高
                const imgWidth = usableWidth;
                const imgHeight = canvasHeight * (imgWidth / canvasWidth);

                let position = marginTop;

                if (i > 0) {
                    pdf.addPage();
                }
                pdf.addImage(imgData, 'PNG', marginLeft, position, imgWidth, imgHeight);
            }
            pdf.save(`${name}.pdf`);
            if (callback) {
                callback()
            }
            if (!isHide) {
                hideLoading()
            }
        } else {
            html2canvas(content).then(canvas => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF('p', 'mm', 'a4');

                // canvas 的宽高
                const canvasWidth = canvas.width;
                const canvasHeight = canvas.height;

                // 计算图片在PDF页面上的宽高
                const imgWidth = usableWidth;
                const imgHeight = canvasHeight * (imgWidth / canvasWidth);

                let heightLeft = imgHeight;
                let position = marginTop;

                // 添加第一张图片
                pdf.addImage(imgData, 'PNG', marginLeft, position, imgWidth, imgHeight);
                heightLeft -= usableHeight;

                // 添加后续页面
                while (heightLeft >= 0) {
                    position = heightLeft - imgHeight + marginTop;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', marginLeft, position, imgWidth, imgHeight);
                    heightLeft -= usableHeight;
                }

                pdf.save(`${name}.pdf`);
                if (callback) {
                    callback()
                }
                if (!isHide) {
                    hideLoading()
                }
            });
        }

    }
}

// 下载pdf报告
export const downloadLocalTemplate = (path) => {
    const nameArr = path.split('/')
    const name = nameArr[nameArr.length - 1]
    fetch(path).then(res => res.blob()).then(blob => {
        let url = window.URL.createObjectURL(blob)
        let a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = name
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
    })
}


export const groupedData = (data) => {
    return data.reduce((acc, current) => {
        if (!acc.all.includes(current)) {
            acc.all.push(current);
            if (!acc.unique.includes(current)) {
                acc.unique.push(current);
            }
        } else {
            if (!acc.duplicates.includes(current)) {
                acc.duplicates.push(current);
            }
        }
        return acc;
    }, {all: [], unique: [], duplicates: []});
}

// 转化行业领域数据格式便于处理
export const transformData = (data) => {
    // 使用一个对象来存储结果，键为第一个元素的值，值为一个包含第二个元素的数组
    const result = {};

    // 遍历原始数据数组
    data.forEach(item => {
        const key = item[0]; // 第一个元素作为键
        const value = item[1] || null; // 第二个元素作为值

        // 如果结果对象中不存在该键，则初始化为一个空数组
        if (!result[key]) {
            result[key] = [];
        }

        // 将第二个元素添加到对应键的数组中
        result[key].push(value);
    });
    // 将结果对象转换成所需的格式：name 和 child 数组
    const transformedArray = Object.keys(result).map(key => ({
        name: key,
        child: result[key].filter(item => item !== '' && item !== null && item !== undefined)
    }));

    return transformedArray;
}

export const transformDataCity = (data) => {
    const result = [];

    // 临时存储省市区的对象
    const provinceMap = {};

    data.forEach(([province, city, area]) => {
        // 如果省份不存在，则创建一个新的省份对象
        if (!provinceMap[province]) {
            provinceMap[province] = {
                name: province,
                child: {}
            };
        }

        // 如果城市不存在，则在省份对象中创建一个新的城市对象
        if (!provinceMap[province].child[city]) {
            provinceMap[province].child[city] = {
                name: city,
                area: new Set()  // 使用 Set 以确保区域不重复
            };
        }

        // 将区域添加到城市的 area 集合中
        provinceMap[province].child[city].area.add(area);
    });

    // 将临时存储的对象转换为所需的数组结构
    for (const province in provinceMap) {
        const provinceObj = {
            name: provinceMap[province].name,
            child: []
        };

        for (const city in provinceMap[province].child) {
            const cityObj = provinceMap[province].child[city];
            provinceObj.child.push({
                name: cityObj.name,
                area: Array.from(cityObj.area)  // 将 Set 转换为数组
            });
        }

        result.push(provinceObj);
    }

    return result;
}

export const transformDataToString = (data) => {
    const provinceMap = {};

    data.forEach(([province, city, area]) => {
        // 如果省份不存在，则创建一个新的省份对象
        if (!provinceMap[province]) {
            provinceMap[province] = {
                cities: {},
                areas: new Set()  // 直接存储北京市的区域
            };
        }

        // 特殊处理北京市
        if (province === '北京市') {
            provinceMap[province].areas.add(area);
        } else {
            // 如果城市不存在，则在省份对象中创建一个新的城市对象
            if (!provinceMap[province].cities[city]) {
                provinceMap[province].cities[city] = new Set();
            }
            // 将区域添加到城市的 area 集合中
            provinceMap[province].cities[city].add(area);
        }
    });

    const result = [];

    for (const province in provinceMap) {
        if (province === '北京市') {
            // 处理北京市的区域
            const areas = Array.from(provinceMap[province].areas).join(',');
            result.push(`${province}:${areas}`);
        } else {
            // 处理其他省份的城市
            const cities = Object.keys(provinceMap[province].cities).join(',');
            result.push(`${province}:${cities}`);
        }
    }

    return result;
}

export const arrowSvg = '<svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="m192 384 320 384 320-384z"></path></svg>'
