<template>
  <InfoCard showExport exportName="导出科创产业分析报告">
    <template #info>
      <div class="graph_map" ref="gmap" :style="{height:boxHeight}">
        <InnerPageMenu></InnerPageMenu>
        <div class="right">
          <el-row :gutter="20" style="height: 100%">
            <el-col :span="14">
              <div class="block_item" style="height: 100%">
                <BlockTitle name="液压挖掘机产业图谱"></BlockTitle>
                <el-row :gutter="20" id="overflow_yauto" class="r_row">
                  <el-col :span="8" v-for="item in typeData" :key="item.name">
                    <div class="top_type_title" :style="{backgroundColor:item.bgColor1}">{{ item.name }}</div>
                    <div class="type_box" :style="{backgroundColor:item.bhColor2}">
                      <el-menu
                          default-active="1"
                          :default-openeds="['1']"
                          :active-text-color="setActiveColor(item.name)"
                      >
                        <el-sub-menu index="1">
                          <template #title>
                            <span>{{ item.subName }}</span>
                          </template>
                          <el-menu-item v-for="sub in item.childMenu" :index="sub" :key="sub">{{ sub }}</el-menu-item>
                        </el-sub-menu>
                      </el-menu>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :span="10">
              <div class="block_item">
                <el-space :size="size">
                  <BlockTitle
                      v-for="item in listName"
                      :key="item"
                      :name="item"
                      :isActive="activeListName === item"
                      @click="changeListName(item)"
                      size="small"
                  ></BlockTitle>
                </el-space>
                <TablePage
                    :columnData="columnData"
                    :tableDataTotal="state.tableDataTotal"
                    :tableData="state.tableData"
                    @changePage="changePage"
                    showIndexColumn
                    :height="tableHeight"
                >
                </TablePage>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </template>
  </InfoCard>
</template>

<script setup>
  import { ref, reactive, onMounted } from 'vue';
  import InfoCard from '@/components/InfoCard.vue';
  import BlockTitle from '@/components/BlockTitle.vue';
  import TablePage from '@/components/TablePage.vue';
  import InnerPageMenu from '@/components/InnerPageMenu.vue';
  import ASelect from '@/components/ASelect.vue';
  import icon1 from '@/assets/kzb/icon-qybq01.png';
  import icon2 from '@/assets/kzb/icon-qybq02.png';
  import icon3 from '@/assets/kzb/icon-qybq03.png';
  import icon4 from '@/assets/kzb/icon-qybq04.png';
  import { getMockPage } from '@/server/dataMock';

  const columnData = [
    {
      prop: 'company',
      label: '企业名称'
    },
    {
      prop: 'name1',
      label: '科创能力'
    }
  ];
  const typeData = [
    {
      name: '上游',
      bgColor1: '#5286EB',
      bhColor2: 'rgba(82,134,235,0.15)',
      subName: '原材料',
      childMenu: ['钢板', '油品', '油漆', '焊丝']
    },
    {
      name: '中游',
      bgColor1: '#FD8E69',
      bhColor2: 'rgba(253,142,105,0.15)',
      subName: '零部件',
      childMenu: ['发动机', '主泵、主阀', '马达、减速机', '油缸']
    },
    {
      name: '下游',
      bgColor1: '#15C2B0',
      bhColor2: 'rgba(21,194,176,0.15)',
      subName: '主机用户',
      childMenu: ['项目用户', '建设用户']
    }
  ];
  const soptions1 = [];
  const listName = ['企业列表', '专利列表', '人才列表', '资金列表'];
  const selectVal1 = ref('');
  const boxHeight = ref('');
  const gmap = ref(null);
  const tableHeight = ref(0);
  const activeListName = ref('企业列表');
  const size = ref(20);
  const state = reactive({
    tableDataTotal: 0,
    tableData: [],
    topTotal: [
      {
        name: '高新技术企业',
        count: 2090,
        icon: icon1
      },
      {
        name: '专精特新企业',
        count: 2090,
        icon: icon2
      },
      {
        name: '上市公司',
        count: 2090,
        icon: icon3
      },
      {
        name: '规模以上企业',
        count: 2090,
        icon: icon4
      }
    ]
  });
  const initData = async (page, pageSize) => {
    const res = await getMockPage(page, pageSize);
    if (res.total && res.total > 0) {
      state.tableData = res.content;
      state.tableDataTotal = res.total;
    }
  };
  const setActiveColor = (name) => {
    if (name === '上游') {
      return '#5286EB';
    }
    if (name === '中游') {
      return '#FD8E69';
    }
    if (name === '下游') {
      return '#15C2B0';
    }
  };
  const changeListName = (name) => {
    activeListName.value = name;
  };
  const updateWindowHeight = () => {
    const ww = window.innerWidth;
    const wh = window.innerHeight;
    if (gmap.value) {
      const of = gmap.value.offsetTop;
      const subh = wh - of - 27;
      boxHeight.value = `${subh}px`;
      tableHeight.value = subh - 132;
      size.value = ww > 1600 ? 20 : 5;
    }
  };
  const changePage = (value) => {
    initData(value, 10);
  };

  onMounted(() => {
    initData(1, 10);
    updateWindowHeight();
    window.addEventListener('resize', updateWindowHeight);
  });
</script>

<style scoped lang="less">
  .graph_map {
    display: flex;
    justify-content: space-between;
    margin-top: 1.42rem;
    overflow-x: hidden;
    overflow-y: auto;

    .right {
      flex: 1;
      height: 100%;
    }
  }

  .r_row {
    height: calc(100% - 4.28rem);
  }

  .top_total {
    display: flex;
    align-items: center;
    padding: 0 30px;
    box-sizing: border-box;
    height: 110px;

    .name_count {
      margin-left: 1.42rem;

      .name {
        font-size: 1em;
        color: #52565C;
      }

      .count {
        font-size: 1.1em;
        color: #000000;
        margin-top: 5px;
      }
    }
  }

  .top_type_title {
    display: flex;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    color: white;
    font-weight: bold;
    font-size: 1.28rem;
    border-radius: 4px;
  }

  .type_box {
    padding: 10px;
    border-radius: 4px;
    margin-top: 10px;
    height: calc(100% - 80px);

    /deep/ .el-menu {
      background-color: transparent;
      border-right: none;
    }

    /deep/ .el-sub-menu .el-menu-item {
      line-height: 36px;
      height: 36px;
    }

    /deep/ .el-menu-item.is-active {
      font-weight: bold;
    }
  }
</style>
