import axios from 'axios';
import {ElMessage} from 'element-plus';
import {goLogin} from '@/untils/index';
// import { useStore } from 'vuex';
import store from '../store/index';
// const globalStore = useStore();

// 创建axios实例
const uploadRequest = axios.create({timeout: 100000}); // 设置默认的请求超时时间

// post请求头的设置
uploadRequest.defaults.headers.post['Content-Type'] = 'multipart/form-data';

uploadRequest.defaults.withCredentials = true;

uploadRequest.interceptors.request.use(
    config => {
        config.headers.Authorization = window.sessionStorage.getItem('token') || '';
        // showLoading();
        store.commit('incrementLoading');
        return config;
    },
    error => {
        store.commit('decrementLoading');
        // hideLoading();
        return Promise.reject(error);
    });

// 响应拦截器
uploadRequest.interceptors.response.use(
    response => {
        if (response && response.status === 200) {
            // hideLoading();
            store.commit('decrementLoading');
            return Promise.resolve(response);
        } else {
            // hideLoading();
            store.commit('decrementLoading');
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                    goLogin();
                    break;
                case 403:
                    ElMessage({
                        message: '登录过期，请重新登录',
                        duration: 3000,
                        type: 'error'
                    });
                    window.sessionStorage.removeItem('token');
                    setTimeout(() => {
                        goLogin();
                    }, 2000);
                    break;
                case 404:
                    ElMessage({
                        message: '网络请求不存在',
                        duration: 3000,
                        type: 'error'
                    });
                    store.commit('decrementLoading');
                    // setTimeout(() => {
                    //   goLogin();
                    // }, 2000);
                    break;
                case 500:
                    ElMessage({
                        message: '服务异常',
                        duration: 3000,
                        type: 'error'
                    });
                    store.commit('decrementLoading');
                    // setTimeout(() => {
                    //   goLogin();
                    // }, 2000);
                    break;
                default:
                    ElMessage({
                        message: error.response.data.message,
                        duration: 3000,
                        type: 'error'
                    });
                    setTimeout(() => {
                        goLogin();
                    }, 2000);
            }

            // hideLoading();
            return Promise.reject(error.response);
        }
    }
);

export default uploadRequest;
