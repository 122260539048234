<template>
  <div class="inner_page_menu block_item" style="height: 100%">
    <el-menu
        class="el-menu-vertical-page"
        default-active="高端装备制造"
        :default-openeds="openeds"
        unique-opened
    >
      <!--      <el-sub-menu v-for="item in menuData" :key="item.name" :index="item.name">-->
      <!--        <template #title>-->
      <!--          <div :class="[currentName === item.name && 'active_p_menu']">-->
      <!--            <el-image v-if="currentName === item.name" :src="item.sIcon" fit="fill"/>-->
      <!--            <el-image v-else :src="item.icon" fit="fill"/>-->
      <!--            <span style="padding-left: 10px">{{ item.name }}</span>-->
      <!--          </div>-->
      <!--        </template>-->
      <!--        <el-menu-item-->
      <!--            v-for="(subName,subIndex) in item.children"-->
      <!--            :key="`${subName}${item.name}${subIndex}`"-->
      <!--            @click="handleClick(item.name,subName)"-->
      <!--            :class="[subName === currentSubName && 'active_sub']"-->
      <!--        >-->
      <!--          {{ subName }}-->
      <!--        </el-menu-item>-->
      <!--      </el-sub-menu>-->
      <el-menu-item
          v-for="item in menuData"
          :key="item.name"
          :index="item.name"
          @click="handleClick(item.name,item.id)"
      >
        <div :class="['menu_item',currentName === item.name && 'active_p_menu']" style="vertical-align: center">
          <el-image v-if="currentName === item.name" :src="item.sIcon" fit="fill"/>
          <el-image v-else :src="item.icon" fit="fill"/>
          <span class="item_name">{{ item.name }}</span>
        </div>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script setup>
import {ref, reactive ,onMounted, defineEmits} from 'vue';
import icon1 from '@/assets/kzb/icon-cyl01.png';
import icons1 from '@/assets/kzb/icon-cyl01-select.png';
import icon2 from '@/assets/kzb/icon-cyl02.png';
import icons2 from '@/assets/kzb/icon-cyl02-select.png';
import icon3 from '@/assets/kzb/icon-cyl03.png';
import icons3 from '@/assets/kzb/icon-cyl03-select.png';
import icon4 from '@/assets/kzb/icon-cyl04.png';
import icons4 from '@/assets/kzb/icon-cyl04-select.png';
import icon5 from '@/assets/kzb/icon-cyl05.png';
import icons5 from '@/assets/kzb/icon-cyl05-select.png';
import icon6 from '@/assets/kzb/icon-cyl06.png';
import icons6 from '@/assets/kzb/icon-cyl06-select.png';
import icon7 from '@/assets/kzb/icon-cyl07.png';
import icons7 from '@/assets/kzb/icon-cyl07-select.png';
import icon8 from '@/assets/kzb/icon-cyl08.png';
import icons8 from '@/assets/kzb/icon-cyl08-select.png';
import icon9 from '@/assets/kzb/icon-cyl09.png';
import icons9 from '@/assets/kzb/icon-cyl09-select.png';
import icon10 from '@/assets/kzb/icon-cyl10.png';
import icons10 from '@/assets/kzb/icon-cyl10-select.png';

import {queryChain} from '@/server/patent'

const openeds = ['高端装备制造'];
const emit = defineEmits(['changeSelect'])

const menuData = reactive([
  {
    name: '高端装备制造',
    icon: icon1,
    sIcon: icons1,
    // children: ['液压挖掘机', '城市轨道交通', '智能制造', '工程起重机', '航空装备']
  },
  {
    name: '特钢材料',
    icon: icon2,
    sIcon: icons2,
    // children: ['液压挖掘机', '城市轨道交通', '智能制造', '工程起重机', '航空装备']
  },
  {
    name: '风电装备',
    icon: icon3,
    sIcon: icons3,
    // children: ['液压挖掘机', '城市轨道交通', '智能制造', '工程起重机', '航空装备']
  },
  {
    name: '铝镁精深加工',
    icon: icon4,
    sIcon: icons4,
    // children: ['液压挖掘机', '城市轨道交通', '智能制造', '工程起重机', '航空装备']
  },
  {
    name: '第三代半导体',
    icon: icon5,
    sIcon: icons5,
    // children: ['液压挖掘机', '城市轨道交通', '智能制造', '工程起重机', '航空装备']
  },
  {
    name: '新能源汽车',
    icon: icon6,
    sIcon: icons6,
    // children: ['液压挖掘机', '城市轨道交通', '智能制造', '工程起重机', '航空装备']
  },
  {
    name: '光伏',
    icon: icon7,
    sIcon: icons7,
    // children: ['液压挖掘机', '城市轨道交通', '智能制造', '工程起重机', '航空装备']
  },
  {
    name: '氢能',
    icon: icon8,
    sIcon: icons8,
    // children: ['液压挖掘机', '城市轨道交通', '智能制造', '工程起重机', '航空装备']
  },
  {
    name: '合成生物',
    icon: icon9,
    sIcon: icons9,
    // children: ['液压挖掘机', '城市轨道交通', '智能制造', '工程起重机', '航空装备']
  },
  {
    name: '现代医药',
    icon: icon10,
    sIcon: icons10,
    // children: ['液压挖掘机', '城市轨道交通', '智能制造', '工程起重机', '航空装备']
  }
]);
const currentName = ref(menuData[0]);
const currentSubName = ref('');

const getList = async () => {
  try{
    const data = await queryChain()
    data.forEach(item => {
      menuData.forEach(itemA => {
        if(item.name == itemA.name){
          itemA.id = item.id
        }
      })
    })
    currentName.value = menuData[0].name
    emit('changeSelect', {name: currentName.value,id: menuData[0].id})
  }catch (e) {
    console.log(e)
  }
}



const handleClick = (name,id) => {
  currentName.value = name;
  // currentSubName.value = sub;
  emit('changeSelect', {name,id})
};

onMounted(()=> {
  getList()
})
</script>

<style scoped lang="less">
.inner_page_menu {
  width: 22.86rem;
  height: calc(100vh - 230px)!important;
  overflow-y: auto;
  margin-right: 1.42rem;
  box-sizing: border-box;
}

/deep/ .el-menu-item {
  height: 3.43rem;
}

.el-menu-item {
  all: unset !important; /* 移除所有属性，包括继承的属性 */
}

.el-menu-vertical-page {
  width: 100%;
  padding: 1rem;
  border-right: none;

  li {
    width: 100%;
    padding: 0 !important;
    overflow: hidden;

    :hover {
      cursor: pointer;
      opacity: 0.8;
      color: #2D6CFF;
    }
  }

  .item_name {
    padding-left: 0.86rem;
    vertical-align: center !important;
    font-size: 1.14rem;
  }

  .menu_item {
    display: flex;
    align-items: center;
    padding: 0 1.43rem;
    height: 3.43rem;
    font-size: 1.42rem;
    box-sizing: border-box;

    .el-image {
      width: 24px;
    }
  }

  /deep/ .el-sub-menu__title {
    padding: 0 !important;
    height: 48px;
    line-height: 48px;

    div {
      display: flex;
      align-items: center;
      padding: 0 10px;
    }
  }

  /deep/ .el-sub-menu .el-menu {
    font-size: 1.14rem;
  }
}

.active_p_menu {
  width: 100%;
  background: #E9EFFF;
  border-radius: 0.57rem;
  font-weight: bold;
  color: #162445;
}

.active_sub {
  width: 100%;
  background: #E9EFFF;
  border-radius: 0.57rem;
  font-weight: bold;
  color: #162445;
}
</style>
