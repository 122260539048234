<template>
  <div class="login_page">
    <div class="login_h">
      <el-image class="login_logo" :src="logoUrl" fit="fill"/>
      <div class="login_phone">
        <el-image class="img" :src="phoneIcon" fit="fill"/>
        <span class="hot_line">热线 : </span>
        <span class="hot_num">{{ phone }}</span>
      </div>
    </div>
    <div class="login_content">
      <div class="l_l">
        <div class="div1">欢迎登录</div>
        <div class="div2">高校专利成果转化智能推荐系统</div>
        <div class="div3">服务专利发明人与科研创新管理机构 促进高校与科研机构打造新质生产力</div>
        <div class="animate__animated animate__bounce">
          <el-image class="login_limage" :src="imgElm"/>
        </div>
      </div>
      <div class="l_r">
        <div v-if="isShowCode && false" class="full_code_box">
          <div class="code_img" @click="showCode">
            <el-image :src="codeImg"></el-image>
          </div>
          <div class="full_code"></div>
        </div>
        <div v-else>
          <div class="code_img" v-if="false" @click="showCode">
            <el-image :src="codeImg"></el-image>
          </div>
          <div class="l_r_title">欢迎来到科转宝</div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane class="animate__animated animate__fadeIn" label="账户登录" name="first">
              <LoginForm></LoginForm>
            </el-tab-pane>
            <el-tab-pane class="animate__animated animate__fadeIn" label="验证码登录" name="second">
              <CodeLogin></CodeLogin>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <div class="login_footer">
      <span>产品介绍</span>
      <el-divider direction="vertical"/>
      <span>关于我们</span>
      <el-divider direction="vertical"/>
<!--      <span>主办方：太原理工技术转移有限公司</span>-->
<!--      <el-divider direction="vertical"/>-->
      <span>技术支持：北京中研硕福科技有限公司</span>
      <el-divider direction="vertical"/>
      <span>京ICP备19055798号-4</span>
    </div>
  </div>
</template>

<!--工包项目主界面-->
<script setup>
import {ref,onMounted} from 'vue';
import logo from '@/assets/kzb/logo-kzb.png';
import telIcon from '@/assets/kzb/icon-tel.png';
import imgElm from '@/assets/kzb/img-elm.png';
import LoginForm from './loginForm';
import CodeLogin from './codeLogin';
import codeImg from '@/assets/kzb/img-tab.png';
import FingerprintJS from "@fingerprintjs/fingerprintjs";

const logoUrl = logo;
const phone = '186-1139-8650';
const phoneIcon = telIcon;
const activeName = ref('first');
const isShowCode = ref(false);

const handleClick = (value) => {
  // console.log(value);
};
const showCode = () => {
  isShowCode.value = !isShowCode.value;
};

const getvisitorId = async () => {
  const fp = await FingerprintJS.load()
  const result = await fp.get()
  const visitorId = result.visitorId
  console.log('唯一标识', visitorId)
  sessionStorage.setItem('deviceId',visitorId)
}

onMounted(()=> {
  getvisitorId()
})
</script>
<style scoped lang="less">
.login_page {
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;
  background-image: url("../../assets/kzb/bg-login.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.login_h {
  width: 100%;
  height: 85px;
  background: #FFFFFF;
  box-shadow: 0 3px 6px 0 rgba(128, 128, 128, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 40px;
}

.login_logo {
  width: 163px; //164
  height: 49px; //50
}

.login_phone {
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #E9E9E9;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 24px;
  margin-right: 240px;

  .img{
    width: 18px;
    height: 18px;

    :deep(img){
      width: 100%;
      height: 100%;
    }
  }

  .hot_line {
    padding: 0 10px;
    color: #171B1E;
  }

  .hot_num {
    font-weight: bold;
    color: #2D6CFF;
    letter-spacing: 1px;
  }
}

.login_content {
  width: 100%;
  margin: 0 auto;
  position: relative;
  height: calc(100% - 171px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 250px;

  .l_l {
    //flex: 1;
    box-sizing: border-box;
    //height: 100%;

    .div1 {
      font-weight: bold;
      font-size: 30px;
      color: #2D6CFF;
    }

    .div2 {
      font-weight: bold;
      font-size: 37px; // 34
      color: #171B1E;
      letter-spacing: 1px;
      margin-top: 15px;
    }

    .div3 {
      font-weight: 400;
      font-size: 22px;
      color: #171B1E;
      letter-spacing: 2px;
      margin-top: 40px;
    }
  }

  .l_r {
    background: #FFFFFF;
    box-shadow: 0 5px 15px 0 rgba(128, 128, 128, 0.2);
    border-radius: 10px;
    padding: 60px 50px; // 50
    position: relative;

    :deep(.el-tabs__item){
      margin-bottom: 15px;
    }

    .full_code_box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .full_code {
      width: 21.42rem; //300
      height: 21.42rem;
      background-color: #2D6CFF;
    }

    .code_img {
      position: absolute;
      right: 0;
      top: 0;
    }

    .code_img:hover {
      cursor: pointer;
      opacity: 0.7;
    }

    .l_r_title {
      font-size: 1.57rem; //22
      color: #171B1E;
      letter-spacing: 1px;
      margin-bottom: 3.57rem; //50
    }
  }
}

.login_limage {
  width: 625px;
  margin-top: 26px;
}

.login_footer {
  width: 80%;
  border-top: 1px solid #E9E9E9;
  height: 86px; //86
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-size: 12px;
  margin: 0 auto;
  color: #717579;
}

</style>
