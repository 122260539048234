<template>
  <el-empty description="无数据"/>
</template>

<script setup>

</script>
<style scoped>

</style>
