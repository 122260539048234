/**
 * [获取cookie]
 * @param {string} cookieName
 * @return {string} cookie
 */
export const setCookie = (cookieName, cookieValue, dayExpira) => {
    //获取当前时间(中国标准时间))
    let time = new Date();
    //将天数转化为毫秒值+当前时间毫秒值=预计过期时间毫秒值
    time.setTime(time.getTime() + (dayExpira * 24 * 60 * 60 * 1000));
    //将预计过期时间毫秒值转化为预计过期时间字符串(格林威治时间)
    let expires = "expires=" + time.toUTCString();
    //cookie存储格式为name=value;expires
    document.cookie = cookieName + "=" + cookieValue + "; " + expires;
}

//参数为cookie的name值
export const getCookie = (cookieName) => {
    let name = cookieName + "=";
    //获取所有的cookie 例:a=123; b=123; c=123 split(去除符号';')
    let cookie = document.cookie.split(';');
    for (let i = 0; i < cookie.length; i++) {
        let cookieQuery = cookie[i];
        //如果查询到的cookie第一个值为空格,则从第二个字符串开始截取
        while (cookieQuery.charAt(0) === ' ') cookieQuery = cookieQuery.substring(1);
        //执行结束
        if (cookieQuery.indexOf(name) !== -1) {
            //返回查询到的cookie的value值
            return cookieQuery.substring(name.length, cookieQuery.length);
        }
    }
    return "";
}

export const clearCookie = () => {

}
