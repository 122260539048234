import axios from 'axios';
import {ElMessage, ElMessageBox} from 'element-plus';
import {deleteDeviceId} from '@/server/login'
import {goLogin} from '@/untils/index';
// import { useStore } from 'vuex';
import store from '../store/index';
// const globalStore = useStore();
import {debounce} from 'lodash';

const instance = axios.create({timeout: 1000 * 60 * 5, baseURL: '/'});

// const showErrorNotification = debounce(() => {
//     ElMessageBox.alert('' +
//         '<p style="display: flex;align-items: center;color: #0A1629"><span style="display: inline-block;width: 5px;height: 5px;background: #0A1629;border-radius: 50%;margin-right: 5px"></span>您的账号已在另一台设备上登录；</p>' +
//         '<p style="display: flex;align-items: center;color: #0A1629"><span style="display: inline-block;width: 5px;height: 5px;background: #0A1629;border-radius: 50%;margin-right: 5px"></span>为了您的账号安全请确认是否是您本人操作；</p>' +
//         '<p style="display: flex;align-items: center;color: #0A1629"><span style="display: inline-block;width: 5px;height: 5px;background: #0A1629;border-radius: 50%;margin-right: 5px"></span>如果不是您本人操作建议立即修改密码！</p>', '账号已在其他设备登录', {
//         confirmButtonText: '确定',
//         dangerouslyUseHTMLString: true,
//         showClose: false,
//         callback: async () => {
//             goLogin();
//         }
//     })
// }, 500)

const showErrorNotification = debounce(() => {
    ElMessageBox.alert('' +
        '<p style="display: flex;align-items: center;color: #0A1629"><span style="display: inline-block;width: 5px;height: 5px;background: #0A1629;border-radius: 50%;margin-right: 5px"></span>您的账号登录过期 或者 已在另一台设备上登录；</p>', '账号已在其他设备登录', {
        confirmButtonText: '确定',
        dangerouslyUseHTMLString: true,
        showClose: false,
        callback: async () => {
            goLogin();
        }
    })
}, 500)

instance.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

instance.defaults.withCredentials = true;
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = window.sessionStorage.getItem('token') || '';
        const notLoadingArr = ['/patents/queryName', '/enterprise/queryForName', '/inventor/queryName', '/skillDemand/queryName', '/patents/exportReport', '/enterprise/exportReport', '/patents/exportPatentInventoryReport', '/patents/exportPNMReport', '/phoneLogin/send', '/exportEnterpriseReport']
        // showLoading();
        if (!notLoadingArr.some(item => config.url.includes(item))) {
            store.commit('incrementLoading');
        }
        return config;
    },
    error => {
        store.commit('decrementLoading');
        // hideLoading();
        return Promise.reject(error);
    });

// 响应拦截器
instance.interceptors.response.use(
    response => {
        if (response && response.status === 200) {
            if (response.data && response.data.code && response.data.code === 401) {
                store.commit('decrementLoading');
                showErrorNotification()
            } else if (response && response.data.code === 10000) {
                store.commit('decrementLoading');
                return Promise.resolve(response)
            } else {
                // hideLoading();
                store.commit('decrementLoading');
                return Promise.resolve(response);
            }
        } else {
            // hideLoading();
            store.commit('decrementLoading');
            return Promise.reject(response);
        }
    },
    error => {
        store.commit('decrementLoading');
        if (error.response && error.response.status) {
            switch (error.response.status) {
                case 401:
                    goLogin();
                    break;
                case 403:
                    ElMessage({
                        message: '登录过期，请重新登录',
                        duration: 3000,
                        type: 'error'
                    });
                    window.sessionStorage.removeItem('token');
                    setTimeout(() => {
                        goLogin();
                    }, 2000);
                    break;
                case 404:
                    ElMessage({
                        message: '网络请求不存在',
                        duration: 3000,
                        type: 'error'
                    });
                    // setTimeout(() => {
                    //   goLogin();
                    // }, 2000);
                    break;
                case 500:
                    ElMessage({
                        message: '服务异常',
                        duration: 3000,
                        type: 'error'
                    });
                    // setTimeout(() => {
                    //   goLogin();
                    // }, 2000);
                    break;
                default:
                    ElMessage({
                        message: error.response.data.message,
                        duration: 3000,
                        type: 'error'
                    });
                    setTimeout(() => {
                        goLogin();
                    }, 2000);
            }
            // hideLoading();
            return Promise.reject(error.response);
        }
    }
);
export default instance;
