import axios from 'axios';
import { ElMessage } from 'element-plus';

const errorCode = {
  '401': '认证失败，无法访问系统资源',
  '403': '当前操作没有权限',
  '404': '访问资源不存在',
  'default': '系统未知错误，请反馈给管理员'
};
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分（在使用mockjs模拟数据时不需要配置baseURL）
  // baseURL: monitorBigScreen,
  // 超时
  timeout: 10000
});

// request拦截器
service.interceptors.request.use(config => {
  return config;
}, error => {
  Promise.reject(error);
});

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = errorCode[code] || res.data.msg || errorCode['default'];
    if (code === 401) {
      ElMessage({
        message: msg,
        duration: 3000,
        type: 'error'
      });
      return Promise.reject('无效的会话，或者会话已过期，请重新登录。');
    } else if (code === 500) {
      ElMessage({
        message: msg,
        duration: 3000,
        type: 'error'
      });
      return Promise.reject(new Error(msg));
    } else if (code === 601) {
      ElMessage({
        message: msg,
        duration: 3000,
        type: 'warning'
      });
      return Promise.reject('error');
    } else if (code !== 200) {
      ElMessage({
        message: msg,
        duration: 3000,
        type: 'error'
      });
      return Promise.reject('error');
    } else {
      return res.data;
    }
  },
  error => {
    let { message } = error;
    if (message === 'Network Error') {
      message = '后端接口连接异常';
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时';
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常';
    }
    ElMessage({
      message: message,
      duration: 3000,
      type: 'error'
    });
    return Promise.reject(error);
  }
);
export default service;

/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get (url, params) {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params: params
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post (url, params) {
  return new Promise((resolve, reject) => {
    service.post(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

/**
 * put方法，对应put请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function put (url, params = {}) {
  return new Promise((resolve, reject) => {
    service.put(url, params)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}

/**
 * Delete方法，对应delete请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function Delete (url, params = {}) {
  return new Promise((resolve, reject) => {
    service.delete(url, { params })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err.data);
      });
  });
}
