import * as echarts from 'echarts';
import {fontSize14} from '@/untils/size'

const colorList = ['#165DFF', '#14C9C9', '#F7BA1E', '#F71E1E', '#FF7D00', '#0085FF', '#00FF66'];
let color1 = ['#165DFF', '#14C9C9', '#F7BA1E', '#722ED1', '#FF7D00'];
let color2 = ['#FF1616', '#14C9C9', '#F7BA1E', '#722ED1', '#FF7D00', '#0085FF', '#00FF66'];
let color3 = ['#165DFF', '#14C93C', '#FF7D00', '#722ED1', '#14C93C'];

const options1 = {
    color: colorList,
    grid: {
        left: 20,
        right: 30,
        bottom: 60,
        top: 30,
        containLabel: true
    },
    tooltip: {
        show: true,
        trigger: 'item',
        // axisPointer: {
        //     type: 'cross',
        //     crossStyle: {
        //         color: '#999'
        //     }
        // }
    },
    legend: {
        show: true,
        bottom: 20,
        itemGap: 20,
        icon: 'roundRect',
        itemWidth: 14,
        itemHeight: 14,
        data: []
    },
    xAxis: [
        {
            type: 'category',
            boundaryGap: 1,
            axisLine: {
                show: false
            },
            axisLabel: {
                color: '#86909C'
            },
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            data: []
        }
    ],
    yAxis: [
        {
            type: 'value',
            nameTextStyle: {
                align: 'center',
                color: '#151515'
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dotted',
                    color: '#eeeeee'
                }
            },
            axisLine: {
                show: false
            },
            axisLabel: {
                show: true,
                margin: 10,
                color: '#86909C'
            },
            axisTick: {
                show: false
            }
        }
    ],
    series: []
};

const options2 = {
    grid: {
        left: 15,
        right: 15,
        bottom: 15,
        top: 20,
        containLabel: true
    },
    tooltip: {
        trigger: 'item'
    },
    xAxis: {
        type: 'value',
        splitLine: {
            show: true,
            lineStyle: {
                type: 'dotted',
                color: '#eeeeee'
            }
        },
        axisTick: {
            show: false
        },
        axisLabel: {
            show: false
        }
    },
    yAxis: [
        {
            type: 'category',
            inverse: true,
            axisLabel: {
                show: true,
                color: '#1D2129',
                fontSize: fontSize14
            },
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            data: []
        },
        {
            type: 'category',
            inverse: true,
            axisLabel: {
                show: true,
                color: '#1D2129',
                fontSize: fontSize14
            },
            splitLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLine: {
                show: false
            },
            data: []
        }
    ],
    series: [
        {
            type: 'bar',
            label: {
                show: false,
                position: 'right',
                color: '#1D2129',
                fontSize: fontSize14
            },
            itemStyle: {
                borderRadius: [0, 5, 5, 0],
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                    {
                        offset: 0,
                        color: '#FFD4B6'
                    },
                    {
                        offset: 1,
                        color: '#FF1616'
                    }
                ])
            },
            barWidth: 10,
            data: []
        }
    ]
};

const options3 = {
    color: color1,
    tooltip: {
        trigger: 'item'
    },
    grid: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0
    },
    legend: {
        show: true,
        bottom: 10,
        itemGap: 20,
        icon: 'roundRect',
        itemWidth: 14,
        itemHeight: 14
    },
    series: [
        {
            type: 'pie',
            radius: ['35%', '65%'],
            center: ['50%', '40%'],
            data: [],
            labelLine: {
                show: false
            },
            label: {
                show: false
            }
        }
    ]
};

const options4 = {
    color: color2,
    tooltip: {
        trigger: 'item'
    },
    grid: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0
    },
    legend: {
        show: true,
        bottom: 10,
        itemGap: 20,
        icon: 'roundRect',
        itemWidth: 16,
        itemHeight: 16
    },
    series: [
        {
            type: 'pie',
            radius: ['35%', '65%'],
            center: ['50%', '40%'],
            data: [],
            labelLine: {
                show: false
            },
            label: {
                show: false
            }
        }
    ]
};

const options5 = {
    color: color3,
    tooltip: {
        trigger: 'item'
    },
    grid: {
        left: 0,
        right: 0,
        bottom: 0,
        top: 0
    },
    legend: {
        show: true,
        bottom: 10,
        itemGap: 20,
        icon: 'roundRect',
        itemWidth: 16,
        itemHeight: 16
    },
    series: [
        {
            type: 'pie',
            radius: ['35%', '65%'],
            center: ['50%', '40%'],
            data: [],
            labelLine: {
                show: false
            },
            label: {
                show: false
            }
        }
    ]
};

export {
    options1,
    options2,
    options3,
    options4,
    options5
};
