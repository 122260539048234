const Mock = require('mockjs');

Mock.setup({
  // 延迟时间200毫秒
  timeout: 200
});

const Random = Mock.Random;
const Result = {
  code: 200,
  msg: '操作成功',
  data: null
};

function getRandomTime () {
  const date = new Date();
  date.setMilliseconds(date.getMilliseconds() - Random.integer(0, 999));
  return date.toISOString();
}

function generateRandomNumber (max, min) {
  const randomNumber = Math.random();
  return Math.floor(randomNumber * (max - min + 1)) + min;
};

const data = Mock.mock({
  'list|30': [{
    'id|+1': 1,
    'name': '@cname',
    'company': '@ctitle',
    'time': getRandomTime()
  }]
});

const dataType = Mock.mock({
  'list|4': [{
    'id|+1': 1,
    'count|+1': 202,
    'countName': ['专利数量', '企业数量', '专家数量', '需求数量']
  }]
});

const pageList = Mock.mock({
  'list|50': [{
    'id|+1': 1,
    'name1': '@cname',
    'name2': '@cname',
    'name3': '@cname',
    'name4': '@cname',
    'name5': '@cname',
    'name6': '@cname',
    'name7': '@cname',
    'name8': '@cname',
    'address': '@county(true)',
    'company': '@ctitle',
    'time': getRandomTime(),
    'time2': getRandomTime(),
    'sortNo1|+1': 1,
    'sortNo2|+1': 1,
    'sortNo3|+1': 1,
    'phone': '@natural',
    'typeName': ['高新技术企业', '上市企业']
  }]
});

const pageListMore = Mock.mock({
  'list|10': [{
    'id|+1': 1,
    'legal': '@cname',
    'industry': '@cname',
    'patentInfo': '@cname',
    'registered|+1': 1000,
    'sortWord': '@cword',
    'natureBusiness': '@cparagraph',
    'location': '@county(true)',
    'company': '@csentence',
    'time': getRandomTime(),
    'sortNo1|+1': 1,
    'phone': '@natural',
    'typeName': ['高新技术企业', '上市企业'],
    'typeName2': ['转让', '变更'],
    'typeName3': ['授权', '驳回'],
    'typeName4': ['有效', '失效'],
    'typeName5': ['财经政法大学'],
    'typeName6': ['电子信息', '新能源与节能']
  }]
});

const chartListType = Mock.mock({
  'list|1': [{
    'id|+1': 1,
    'type3': ['有效', '失效', '审查中'],
    'type4': ['专利总量', '发明专利', '实用新型', '外观设计'],
    'type5': ['发明申请', '发明授权', '实用新型', '外观设计', '其他'],
    'type7': ['转让', '许可', '质押', '诉讼', '保全', '变更', '进入国家']
  }]
});

const chartListData = Mock.mock({
  'list|10': [{
    'id|+1': 1,
    'name': '@cname',
    'value|+1': generateRandomNumber(500, 200)
  }]
});

Mock.mock('/testMockList', 'get', () => {
  return {
    code: 200,
    data: data.list
  };
});

Mock.mock('/testMockType', 'get', () => {
  return {
    code: 200,
    data: dataType.list
  };
});

Mock.mock('/testMockChartType', 'get', () => {
  return {
    code: 200,
    data: chartListType.list
  };
});

Mock.mock('/testMockChartData', 'get', () => {
  return {
    code: 200,
    data: chartListData.list
  };
});

Mock.mock('/testMockPage', 'post', (res) => {
  const obj = JSON.parse(res.body);
  const splitStart = (obj.page - 1) * 10;
  const splitEnd = obj.pageSize * obj.page;
  return {
    code: 200,
    data: {
      content: pageList.list.slice(splitStart, splitEnd),
      total: pageList.list.length
    }
  };
});

Mock.mock('/testMockPageMore', 'post', (res) => {
  const obj = JSON.parse(res.body);
  const splitStart = (obj.page - 1) * 10;
  const splitEnd = obj.pageSize * obj.page;
  return {
    code: 200,
    data: {
      content: pageListMore.list.slice(splitStart, splitEnd),
      total: pageListMore.list.length
    }
  };
});

