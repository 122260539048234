<template>
  <div class="g_layout" id="gLayout">
    <HeadTempUser></HeadTempUser>
    <div class="g_l_content">
      <MenuTempUser></MenuTempUser>
      <div class="content">
        <router-view v-slot="{ Component,route }">
          <keep-alive>
            <component :is="Component" :key="route.fullPath"></component>
          </keep-alive>
        </router-view>
      </div>
    </div>
  </div>
</template>

<script setup>
import HeadTempUser from '@/components/HeadTempUser.vue';
import MenuTempUser from '@/components/MenuTempUser.vue';
import {getUserInfo} from "@/server/users";
import {onMounted} from "vue";
import {useStore} from "vuex";

const globalStore = useStore();
const initData = () => {
  return new Promise(async resolve => {
    const res = await getUserInfo()
    if (res) {
      const {school, user} = res
      globalStore.commit('setCurrentUser', user.nickName);
      sessionStorage.setItem('userId',user.userId)
      if (user.userName !== 'admin') {
        globalStore.commit('setGlobalName', school);
      } else {
        globalStore.commit('setGlobalName', {});
      }
    }
  })
}
onMounted(() => {
  initData()
})
</script>
<style scoped lang="less">
.g_layout {
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  height: 100%;
}

.g_l_content {
  width: 100%;
  height: calc(100vh - 85px); //85
  display: flex;
  box-sizing: border-box;

  .content {
    flex: 1;
    width: calc(100vw - 100px);
    box-sizing: border-box;
    height: calc(100vh - 85px);
    overflow: hidden;
  }
}
</style>
