<template>
  <div class="g_menu">
    <div
        v-for="(item,index) in state.menuData"
        :key="item.path"
        :class="['nav_li',globalStore.state.currentPath === item.path && 'menu_active']"
        @click="changePage(item)"
        @mouseenter="switchIcon('show',index)"
        @mouseleave="switchIcon('hide',index)"
    >
      <el-image
          v-if="globalStore.state.currentPath === item.path"
          class="icon_image"
          :src="item.sIcon"
          fit="fill"></el-image>
      <el-image
          v-else
          class="icon_image"
          :src="globalStore.state.currentPath === item.path ? item.sIcon : item.icon"
          fit="fill"></el-image>
      <div style="margin-top: 5px">
        {{ item.cnName }}
      </div>
      <div v-if="isShowTip && index === 5" class="right_tip">
        暂未上线,敬请期待
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, reactive, onMounted} from 'vue';
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';
import icon1 from "@/assets/kzb/icon-nav01.png";
import sIcon1 from "@/assets/kzb/icon-nav01-select.png";
import icon2 from "@/assets/kzb/icon-nav-02.png";
import sIcon2 from "@/assets/kzb/icon-nav-02-select.png";
import icon3 from "@/assets/kzb/icon-nav-03.png";
import sIcon3 from "@/assets/kzb/icon-nav-03-select.png";
import icon4 from "@/assets/kzb/icon-nav-04.png";
import sIcon4 from "@/assets/kzb/icon-nav-04-select.png";
import icon5 from "@/assets/kzb/icon-nav-05.png";
import sIcon5 from "@/assets/kzb/icon-nav-05-select.png";
import icon6 from "@/assets/kzb/icon-nav-06.png";
import sIcon6 from "@/assets/kzb/icon-nav-06-select.png";

const icons = [
  {
    icon: icon1,
    sIcon: sIcon1
  },
  {
    icon: icon2,
    sIcon: sIcon2
  },
  {
    icon: icon3,
    sIcon: sIcon3
  },
  {
    icon: icon4,
    sIcon: sIcon4
  },
  {
    icon: icon5,
    sIcon: sIcon5
  },
  {
    icon: icon6,
    sIcon: sIcon6
  }
];

import route from '@/router/index'

const globalStore = useStore();
const routers = useRouter();

const activeIndex = ref(-1);
const isShowTip = ref(false)

const state = reactive({
  menuData: []
})

const changePage = (item) => {
  if (item.name === '投资机构') return;
  if (item.name === '专利') {
    globalStore.commit('setHeadSelectValue', item.name);
  } else if (item.name === '发明人') {
    globalStore.commit('setHeadSelectValue', item.name);
  } else if (item.name === '技术需求') {
    globalStore.commit('setHeadSelectValue', '技术');
  } else if (item.name === '企业') {
    globalStore.commit('setHeadSelectValue', item.name);
  }
  routers.push(item.path);
  globalStore.commit('setTopSearchValue', '');
  globalStore.commit('setSearchWord', {});
};
const switchIcon = (type, index) => {
  isShowTip.value = type === 'show' && index === 5
  activeIndex.value = type === 'show' ? index : -1;
};
onMounted(() => {
  const {options: {routes}} = route
  const d1 = routes[1].children
  const md1 = d1.filter(item => item.curType === 'main')
  state.menuData = md1.map((item, index) => {
    item = {
      ...item,
      ...icons[index]
    };
    return item;
  })
})
</script>

<style scoped lang="less">
.g_menu {
  width: 100px; //100
  height: calc(100% - 85px);
  background: #FFFFFF;
  box-shadow: 0 0 6px 0 rgba(128, 128, 128, 0.2);
  padding: 2.85rem 0; //40
  box-sizing: border-box;
  font-size: 1rem;
  position: fixed;
  top: 85px;
}

.icon_image {
  width: 1.85rem; //26
  height: 1.85rem;
}

.nav_li {
  width: 80px; // 80
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto 30px auto; //30
  box-sizing: border-box;
  position: relative;
  font-size: 14px;
}

.right_tip {
  position: absolute;
  width: 9.28rem; //130
  height: 40px;
  line-height: 40px;
  text-align: center;
  right: -9.28rem;;
  background: #0A1629;
  color: white;
  z-index: 99;
  border-radius: 10px;
  transition: all 3s;
}

.nav_li:hover {
  cursor: pointer;
  background: #84AFFF;
  border-radius: 10px;
  transition: all .3s;
}

.menu_active {
  background: #2D6CFF;
  border-radius: 10px;
  color: white;
}
</style>
