import instance from './index';
import uploadRequest from './upload';

// 普通请求
const ajax = (url = '', params = {}, type = 'GET', responseType = '') => {
    let promise;
    return new Promise((resolve, reject) => {
        //  判断请求的方式
        if (type === 'GET') {
            let paramsStr = '';
            Object.keys(params).forEach(key => {
                paramsStr += key + '=' + params[key] + '&';
            });
            if (paramsStr !== '') {
                paramsStr = paramsStr.substr(0, paramsStr.lastIndexOf('&'));
            }
            url = paramsStr ? `${url}?${paramsStr}` : url;
            promise = instance.get(url);
        } else if (type === 'PUT') {
            promise = instance.put(url, params);
        } else if (type === 'DELETE') {
            promise = instance.delete(url, params);
        } else {
            promise = instance.post(url, params, {responseType: responseType});
        }
        promise.then((res) => {
            resolve(res && res.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

// 带有文件的请求
const ajaxUpload = (url = '', params = {}, type = 'GET', responseType = '') => {
    let promise;
    return new Promise((resolve, reject) => {
        //  判断请求的方式
        if (type === 'GET') {
            let paramsStr = '';
            Object.keys(params).forEach(key => {
                paramsStr += key + '=' + params[key] + '&';
            });
            if (paramsStr !== '') {
                paramsStr = paramsStr.substr(0, paramsStr.lastIndexOf('&'));
            }
            url += '?' + paramsStr;
            promise = uploadRequest.get(url);
        } else if (type === 'PUT') {
            promise = uploadRequest.put(url, params);
        } else if (type === 'DELETE') {
            promise = uploadRequest.delete(url, params);
        } else {
            promise = uploadRequest.post(url, params, {'responseType': responseType});
        }
        promise.then((res) => {
            resolve(res.data);
        }).catch((err) => {
            reject(err);
        });
    });
};

export {
    ajax,
    ajaxUpload
};
