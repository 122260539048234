import { ajax } from './ajax';

import { BASE_URL } from './baseUrl';

const getCodeImage = () => ajax(BASE_URL + '/captchaImage');

const setPhoneCode = (phoneNumber) => ajax(BASE_URL + `/phoneLogin/send?phoneNumber=${phoneNumber}`);

const checkPhoneCode = (phoneNumber, code,flag,deviceId) => ajax(BASE_URL + `/phoneLogin/check?phoneNumber=${phoneNumber}&code=${code}&flag=${flag}&deviceId=${deviceId}`);

const login = (params) => ajax(BASE_URL + '/login', params, 'POST');

const logout = (params) => ajax(BASE_URL + '/logout',null,'POST')

const deleteDeviceId = (params) => ajax(BASE_URL + '/system/user/deleteDeviceId',params,'POST')

const getUserInfo = () => ajax(BASE_URL + '/getInfo');

export {
  getCodeImage,
  login,
  setPhoneCode,
  checkPhoneCode,
  getUserInfo,
  logout,
  deleteDeviceId
};
