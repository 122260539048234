<template>
  <div class="g_layout overflow_yauto" id="gLayout" ref="globalContainer">
    <HeadTemp></HeadTemp>
    <div class="g_l_content">
      <MenuTemp></MenuTemp>
      <div class="content">
        <!--        <transition name="el-fade-in-linear">-->
        <router-view v-slot="{ Component,route }">
          <keep-alive>
            <component :is="Component" :key="route.fullPath"></component>
          </keep-alive>
        </router-view>
        <!--        </transition>-->
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted} from 'vue';
import HeadTemp from '@/components/HeadTemp.vue';
import MenuTemp from '@/components/MenuTemp.vue';
import {useStore} from "vuex";
import {getUserInfo} from '@/server/users'

const globalContainer = ref(null)
const globalStore = useStore();

const cacheRouterArr = ref(['/patent', '/enterprise', '/inventor', '/technical'])

const initData = async () => {
  const res = await getUserInfo()
  if (res) {
    const {school, user} = res
    globalStore.commit('setCurrentUser', user.nickName);
    sessionStorage.setItem('userId', user.userId)
    if (user.userName !== 'admin') {
      globalStore.commit('setGlobalName', school);
      localStorage.setItem('school_deptName', school && school.deptName)
    } else {
      globalStore.commit('setGlobalName', {});
      localStorage.setItem('school_deptName', '')
    }
  }
}

onMounted(() => {
  globalStore.commit('setGlobalContainer', globalContainer);
  initData()
})
</script>
<style scoped lang="less">
.g_layout {
  width: 100%;
  box-sizing: border-box;
  //overflow: hidden;
  height: 100%;
}

.g_l_content {
  width: 100%;
  //height: calc(100% - 6.07rem);//85
  //height: 100%;
  //height: inherit;
  display: flex;
  box-sizing: border-box;
  min-height: calc(100% - 6.07rem);

  .content {
    flex: 1;
    width: calc(100vw - 100px);
    box-sizing: border-box;
    height: inherit;
    margin-left: 100px;
    height: calc(100vh - 85px);
    //overflow: hidden;
  }
}
</style>
