<template>
  <div class="table_page">
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" :header-cell-style="{'text-align': 'center','background': '#F2F3F5 !important','color': '#31373D','height':'4.28rem'}"
          highlight-current-row @row-click="rowDoubleClick" :max-height="maxHeight" :height="height" :cell-style="{'cursor':hideClick ? '' : 'pointer'}">
        <template v-if="showIndexColumn">
          <el-table-column align="center" label="序号" width="70">
            <template #default="scope">
              {{ currentPageNum > 1 ? ((currentPageNum - 1) * currentPageSize) + (scope.$index + 1) : scope.$index + 1 }}
            </template>
          </el-table-column>
        </template>
        <el-table-column v-for="item in columnData" :align="item.align" :key="item.prop" :prop="item.prop" :label="item.label"
            :show-overflow-tooltip="item.showTooltip==null || item.showTooltip==undefined || item.showTooltip" :width="item.width || 'auto'">
          <template #default="scope">
            <span v-if="scope.row[item.prop]">{{scope.row[item.prop]}}</span>
            <span v-else>-</span>
          </template>
          <template #default="scope" v-if="item.slotName">
            <slot :name="item.slotName" :row="scope.row"></slot>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
        v-if="!hidePagination"
        background
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        @change="handChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        :page-size="PageSize"
        :current-page="currentPage"
        :total="tableDataTotal"
        :style="setStyle(isPos)"
    >
    </el-pagination>
  </div>
</template>
<script setup>
import {defineEmits, defineProps, ref} from 'vue';

const emit = defineEmits(['changePage', 'rowClick']);

const props = defineProps({
  tableData: Array,
  tableDataTotal: Number,
  columnData: Array,
  showIndexColumn: Boolean,
  hidePagination: Boolean,
  height: Number,
  maxHeight: {
    type: [Number, String],
    default: 'calc(100vh - 250px)'
  },
  hideClick: Boolean,
  isPos: Boolean,
  PageSize: {
    type: Number,
    default: 10
  },
  currentPage: {
    type: Number,
    default: 1
  }
});

const currentPageNum = ref(1)
const currentPageSize = ref(0)

const handleCurrentChange = (value) => {
  emit('changePage', value);
};

const handChange = (currentPag, pageSize) => {
  // console.log(pageSize)
  currentPageNum.value = currentPag
  currentPageSize.value = pageSize
}
const prevClick = (value) => {
  emit('changePage', value);
};
const nextClick = (value) => {
  emit('changePage', value);
};
const rowDoubleClick = (row) => {
  if (props.hideClick) return
  emit('rowClick', row)
};

const setStyle = (pos) => {
  if (pos) {
    return {
      // position: 'absolute',
      // bottom: '1.14rem',
      // left: '50%',
      // transform: 'translateX(-50%)'
      transform: 'scale(1.1)',
      paddingTop: '10px'
    }
  }
}

</script>

<style scoped lang="less">
.table_page {
  width: 100%;

  /deep/ .el-table {
    color: #31373D;
    font-size: 16px;
  }

  /deep/ .el-table .el-table__cell {
    padding: 18px 0;
  }

  .tableBox{
    border-left: 1px solid #ebeef5;
    border-right: 1px solid #ebeef5;
  }
}
</style>
