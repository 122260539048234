import { ajax } from './ajax';
import { get, post } from './axiosMock';


/* mock */
export const getMockHomeList = () => get('/testMockList');
export const getMockHomeType = () => get('/testMockType');
export const getMockPage = (page = 1, pageSize = 10) => post('/testMockPage', { page, pageSize });
export const testMockPageMore = (page = 1, pageSize = 10) => post('/testMockPageMore', { page, pageSize });
export const getMockChartType = () => get('/testMockChartType');
export const getMockChartData = () => get('/testMockChartData');
