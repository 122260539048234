<template>
  <div class="g_head">
    <div class="g_head_l">
      <el-image class="head_logo1" :src="logoUrl" fit="fill"/>
      <el-image
          class="head_logo"
          v-if="globalStore.state.globalLogo && globalStore.state.globalLogo !==''"
          :src="globalStore.state.globalLogo"
          fit="fill"/>
      <div class="sys_name" v-if="globalStore.state.globalName && globalStore.state.globalName !== ''">
        {{ globalStore.state.globalName }}专利成果转化智能推荐系统
      </div>
    </div>
    <div class="g_head_r">
      <div v-if="globalStore.state.isHideSearch === '1'">
        <el-input
            v-model="globalStore.state.topSearchValue"
            :placeholder="globalStore.state.headSearchPlaceholder"
            class="input-with-select"
            size="large"
        >
          <template #prepend>
            <el-select
                size="large"
                v-model="globalStore.state.headSelectValue"
                style="width: 9rem;font-size: 1.14rem"
                @change="handleChangeSelect"
            >
              <el-option style="font-size: 1.14rem" v-for="item in options" :key="item" :label="item" :value="item"/>
            </el-select>
          </template>
          <template #append>
            <el-button :icon="Search" @click="handleTopSearch">搜索</el-button>
          </template>
        </el-input>
      </div>
      <div
          class="home_nav"
          @mouseenter="switchHomeNavIcon('show')"
          @mouseleave="switchHomeNavIcon('hide')"
      >
        <el-dropdown @command="changeHomeNav">
          <span class="el-dropdown-link">
            <el-image style="width: 1.28rem; height: 1.28rem" :src="homeNav" fit="fill"/>
            <span class="home_nav_tip">应用场景</span>
            <el-icon v-if="homeNavActive"><CaretTop/></el-icon>
            <el-icon v-else><CaretBottom/></el-icon>
          </span>
          <template #dropdown>
            <el-dropdown-menu class="drop_menu_box">
              <el-dropdown-item v-for="(item,index) in usePlace" :key="item.id" :command="item"
                                @mouseenter="switchIcon('show',index)" @mouseleave="switchIcon('hide',index)">
                <div :class="['drop_item',globalStore.state.homeTopActive === item.name && 'drop_item_active']">
                  <el-image
                      v-if="activeIndex === index"
                      style="width: 1.29rem; height: 1.29rem;margin-right: 0.71rem"
                      :src="item.sIcon"
                      fit="fill"
                  />
                  <el-image
                      v-else
                      style="width: 1.29rem; height: 1.29rem;margin-right: 0.71rem"
                      :src="globalStore.state.homeTopActive === item.name ? item.sIcon : item.icon"
                      fit="fill"
                  />
                  <span class="fs16">{{ item.name }}</span>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
      <div class="avatar ellipsis_text">
        <el-dropdown placement="bottom-end">
          <span class="avatar_box">
            <el-avatar
                style="width: 3.21rem;height: 3.21rem;margin-right: 0.71rem"
                v-if="globalStore.state.globalLogo !==''"
                :src="globalStore.state.globalLogo"/>
            您好，<span>{{ globalStore.state.currentUser }}</span>
          </span>
          <template #dropdown>
            <el-dropdown-menu class="avatar_box_item">
              <el-dropdown-item @click="onChangeUser('1')" class="avatar_box_itemli">
                <el-icon size="16">
                  <Setting/>
                </el-icon>
                <span class="fs16">用户设置</span>
              </el-dropdown-item>
              <el-dropdown-item @click="onChangeUser('2')" class="avatar_box_itemli">
                <el-icon size="16">
                  <Clock/>
                </el-icon>
                <span class="fs16">历史记录</span>
              </el-dropdown-item>
              <el-dropdown-item @click="onChangeUser('3')" class="avatar_box_itemli">
                <el-icon size="16">
                  <Star/>
                </el-icon>
                <span class="fs16">我的收藏</span>
              </el-dropdown-item>
              <el-dropdown-item @click="onChangeUser('4')" class="avatar_box_itemli">
                <el-icon size="16">
                  <Document/>
                </el-icon>
                <span class="fs16">我的需求</span>
              </el-dropdown-item>
              <el-dropdown-item @click="goOut" class="avatar_box_itemli">
                <el-icon size="16">
                  <SwitchButton/>
                </el-icon>
                <span class="fs16">退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, defineProps} from 'vue';
import {useRouter} from 'vue-router';
import {Search, CaretTop, CaretBottom, Tools, SwitchButton, Setting, Clock, Star,Document} from '@element-plus/icons-vue';
import {useStore} from 'vuex';
import logo from '@/assets/kzb/logo-kzb.png';
import logo2 from '@/assets/kzb/logo-zbdx.png';
import homeNavIcon from '@/assets/kzb/icon-yycj.png';
import sHomeNavIcon from '@/assets/kzb/icon-yycj-select.png';
import icon1 from '@/assets/kzb/icon-yycj01-normal.png';
import sIcon1 from '@/assets/kzb/icon-yycj01-select.png';
import icon2 from '@/assets/kzb/icon-yycj02-normal.png';
import sIcon2 from '@/assets/kzb/icon-yycj02-select.png';
import icon3 from '@/assets/kzb/icon-yycj03-normal.png';
import sIcon3 from '@/assets/kzb/icon-yycj03-select.png';
import icon4 from '@/assets/kzb/icon-yycj04-normal.png';
import sIcon4 from '@/assets/kzb/icon-yycj04-select.png';
import icon5 from '@/assets/kzb/icon-yycj05-normal.png';
import sIcon5 from '@/assets/kzb/icon-yycj05-select.png';
import icon6 from '@/assets/kzb/icon-yycj06-normal.png';
import sIcon6 from '@/assets/kzb/icon-yycj06-select.png';
import icon7 from '@/assets/kzb/icon-yycj07-normal.png';
import sIcon7 from '@/assets/kzb/icon-yycj07-select.png';
import {goLogin} from '@/untils/index';
import {ElMessage} from 'element-plus';
import router from '@/router/index'
import {getEnterprisePage} from "@/server/enterprise";
import {getPatentPage} from "@/server/patent";
import {getInventorPage} from "@/server/inventor";
import {getTechnicalPage} from "@/server/technical";
import store from "@/store";

const globalStore = useStore();
const routers = useRouter();
const logoUrl = logo;
const logoUrl2 = logo2;
const usePlace = [
  {
    name: '企业-专利双向匹配',
    icon: icon1,
    sIcon: sIcon1,
    path: '/patentEnterpriseMatchNew',
    leftSidePath: '/enterprise',
    id: '1'
  },
  {
    name: '技术需求发布',
    icon: icon2,
    sIcon: sIcon2,
    path: '/technicalRelease',
    leftSidePath: '/technical',
    id: '2'
  },
  {
    name: '专利导航图谱',
    icon: icon3,
    sIcon: sIcon3,
    path: '/patentMapGraph',
    leftSidePath: '/patent',
    id: '3'
  },
  // {
  //   name: '产业链图谱',
  //   icon: icon4,
  //   sIcon: sIcon4,
  //   path: '/industryChainGraph'
  // },
  {
    name: '院校存量专利盘点',
    icon: icon5,
    sIcon: sIcon5,
    path: '/patentColleges',
    leftSidePath: '',
    id: '4'
  },
  {
    name: '科技成果转化地图',
    icon: icon6,
    sIcon: sIcon6,
    path: '/technologyMap',
    leftSidePath: '',
    id: '5'
  },
  {
    name: '院校专利成果转化仪表盘',
    icon: icon7,
    sIcon: sIcon7,
    path: '/collegePatentDashboard',
    leftSidePath: '',
    id: '6'
  }
];
const options = ['查专利', '查企业', '查发明人', '查技术'];

const activeIndex = ref(-1);
const homeNav = ref(homeNavIcon);
const homeNavActive = ref(false);

const initDataEnterprise = async (pageNum, pageSize, keyWord) => {
  const params = {
    pageNum,
    pageSize,
    mohu: keyWord
  };
  const res = await getEnterprisePage(params);
  if (res && res.code === 200) {
    const {rows, total} = res;
    globalStore.commit('setEnterprise', {
      data: rows.map(item => {
        // if (item.tags && item.tags !== '') {
        //   // 使用split方法按照$$切割成数组
        //   let splitArray = item.tags.split('$$');
        //   // 使用filter方法去掉"曾用名"
        //   item.tags = splitArray.filter(item => item !== '曾用名');
        // }
        return item;
      }),
      total
    });
  }
};
const initDataPatent = async (pageNum, pageSize, keyWord) => {
  const params = {
    pageNum,
    pageSize,
    mohu: keyWord,
    zlr: localStorage.getItem('school_deptName') || ''
  };
  const res = await getPatentPage(params);
  if (res && res.code === 200) {
    const {rows, total} = res;
    globalStore.commit('setPatent', {
      data: rows,
      total
    });
  }
};
const initDataInventor = async (pageNum, pageSize, keyWord) => {
  const params = {
    pageNum,
    pageSize,
    mohu: keyWord,
    college: localStorage.getItem('school_deptName')
  };
  const res = await getInventorPage(params);
  if (res && res.code === 200) {
    const {rows, total} = res;
    globalStore.commit('setInventor', {
      data: rows,
      total
    });
  }
};
const initDataTechnical = async (pageNum, pageSize, keyWord) => {
  const params = {
    pageNum,
    pageSize,
    mohu: keyWord
  };
  const res = await getTechnicalPage(params);
  if (res && res.code === 200) {
    const {rows, total} = res;
    globalStore.commit('setTechnical', {
      data: rows,
      total
    });
  }
};
const changeHomeNav = (item) => {
  if (item.path == '/collegePatentDashboard') {
    const pathHref = router.resolve({
      path: '/collegePatentDashboard'
    })
    window.open(pathHref.href, '_blank')
  } else {
    routers.push(item.path);
    globalStore.commit('setHeadSelectValue', '企业');
  }
};
const switchHomeNavIcon = (type) => {
  homeNavActive.value = type === 'show';
  homeNav.value = type === 'show' ? sHomeNavIcon : homeNavIcon;
};
const handleChangeSelect = (val) => {
  let word;
  if (val === '查企业') {
    word = '企业'
  }
  if (val === '查专利') {
    word = '专利'
  }
  if (val === '查发明人') {
    word = '发明人'
  }
  if (val === '查技术') {
    word = '技术'
  }
  globalStore.commit('setHeadSelectValue', word);
  let path;
  switch (val) {
    case '查企业':
      path = '/enterprise';
      break;
    case '查发明人':
      path = '/inventor';
      break;
    case '查技术':
      path = '/technical';
      break;
    default:
      path = '/patent';
      break;
  }
  // 判断是否为当前页
  if (path === globalStore.state.currentPath) return;
  routers.push(path);
}
const handleTopSearch = () => {
  if (globalStore.state.topSearchValue === '') return ElMessage.error('请输入关键词再检索');
  // ['查专利', '查企业', '查发明人', '查技术', '查机构']
  let path;
  globalStore.commit('setSearchWord', {keyWord: `关键词: ${globalStore.state.topSearchValue}`});
  switch (globalStore.state.headSelectValue) {
    case '查企业':
      path = '/enterprise';
      initDataEnterprise(1, 10, globalStore.state.topSearchValue)
      break;
    case '查发明人':
      path = '/inventor';
      initDataInventor(1, 10, globalStore.state.topSearchValue)
      break;
    case '查技术':
      path = '/technical';
      initDataTechnical(1, 10, globalStore.state.topSearchValue)
      break;
    case '查专利':
      path = '/patent';
      initDataPatent(1, 10, globalStore.state.topSearchValue)
      break;
    default:
      break;
  }
  if (path === globalStore.state.currentPath) return;
  routers.push(path);
};
const switchIcon = (type, index) => {
  activeIndex.value = type === 'show' ? index : -1;
};
const onChangeUser = (type) => {
  let path;
  if (type === '1') {
    path = '/personal'
  }
  if (type === '2') {
    path = '/history'
  }
  if (type === '3') {
    path = '/favorites'
  }
  if(type === '4'){
    path = '/myNeed'
  }
  const pathHref = router.resolve({
    path: path
  })
  window.open(pathHref.href, '_blank')
};
const goOut = () => {
  globalStore.dispatch('logOut').then(() => {
    goLogin();
  })
};

</script>

<style scoped lang="less">
.g_head {
  width: 100%;
  height: 85px; //85
  background: #FFFFFF;
  box-shadow: 0 3px 6px 0 rgba(128, 128, 128, 0.2);
  padding: 0 50px 0 30px; //70 30
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 999;
}

.g_head_l, .g_head_r {
  display: flex;
  align-items: center;
}

.g_head_l {
  .head_logo1 {
    width: 163px; //164
    height: 49px; //50
    box-sizing: border-box;
  }

  .head_logo {
    width: 50px; //50
    height: 50px; //50
    margin: 0 0.86rem 0 2.85rem;
    box-sizing: border-box;

    img {
      width: 50px; //50
    }
  }

  .sys_name {
    font-weight: bold;
    font-size: 20px; //20
    //letter-spacing: 1px;
  }

  //.sys_name:hover {
  //  cursor: pointer;
  //}
}

.input-with-select {
  width: 600px; //660
  height: 45px; //
  box-sizing: border-box;
  //min-width: 460px;
  font-size: 1rem;

  /deep/ .is-focus {
    box-shadow: none !important;
    border: none !important;
  }

  /deep/ .is-focused {
    box-shadow: none !important;
    border: none !important;
  }
}

.g_head_r {
  font-size: 1rem;

  .avatar {
    display: flex;
    align-items: center;

    /deep/ .el-dropdown, /deep/ .el-dropdown-menu {
      font-size: inherit;
    }

    .avatar_box {
      display: flex;
      align-items: center;
      font-size: 1.14rem;
    }

    .avatar_box:hover {
      cursor: pointer;
      color: #2D6CFF;
    }
  }

  /deep/ .el-input-group {
    border: 1px solid #2D6CFF;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    background: #fff;
  }

  /deep/ .el-input-group--append > .el-input__wrapper,
  /deep/ .el-input__wrapper {
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  /deep/ .el-input-group__prepend,
  /deep/ .el-input-group--prepend .el-input-group__prepend .el-select .el-select__wrapper {
    background-color: transparent;
    box-shadow: none;
    font-size: 1rem;
  }

  /deep/ .el-input-group__append {
    width: 7.85rem; //110
    box-sizing: border-box;
    background-color: #2D6CFF;
    border-radius: 0.71rem;
    margin-right: 1px;

    button {
      display: flex;
      align-items: center;
      color: white;
    }
  }
}

.home_nav {
  width: 160px; //160
  height: 45px; //44
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 0.71rem;
  border: 1px solid #E9E9E9;
  margin: 0 3.57rem;
  //min-width: 130px;

  /deep/ .el-dropdown {
    width: 100%;
    height: 100%;
  }

  .el-dropdown-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.71rem;
    color: #171B1E;
    font-size: 1.14rem;
    cursor: pointer;
  }

  .el-dropdown-link:hover {
    background: #2D6CFF;
    color: white;
    transition: all .3s;
  }

  .home_nav_tip {
    font-weight: bold;
    padding: 0 0.71rem;
  }
}

.drop_menu_box {
  //padding: 0.71rem 1.42rem;
}

/deep/ .el-dropdown:focus {
  outline: none;
}

/deep/ .el-dropdown:focus-visible {
  outline: none;
}

/deep/ .el-dropdown-menu__item {
  line-height: 3.43rem;
  padding: 0 1.5rem;
}

.drop_item {
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.71rem;
  margin: 1px 0;
}

.drop_item:hover {
  background: #2D6CFF;
  color: white;
  transition: all .3s;
  border-radius: 0.71rem;
}

.drop_item_active {
  background: #2D6CFF;
  color: white;
  border-radius: 0.71rem;
}

.fs16{
  font-size: 16px!important;
}

.avatar_box_item{
  padding: 0!important;

  :deep(span){
    font-size: 16px;
  }
}
</style>
