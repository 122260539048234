import {createRouter,createWebHashHistory,createWebHistory} from 'vue-router'
import LoginPage from '../views/login/index.vue'
import LayoutPage from '../views/layout/index.vue'
import UserLayout from '../views/layout/userLayout.vue'
import patentMapGraph from '../views/patentMapGraph/index.vue'
import industryChainGraph from '../views/industryChainGraph/index.vue'
import store from '../store'

const routes = [    {
        path: '/',
        name: 'login',
        cnName: '登录',
        component: LoginPage
    },
    {
        path: '/root',
        name: '根路径',
        cnName: '根路径',
        curType: 'main',
        component: LayoutPage,
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: '首页',
                cnName: '首页',
                curType: 'main',
                id: '1',
                meta: {
                    type: 'home'
                },
                component: () => import('../views/home/index.vue')
            },
            {
                path: '/patentEnterpriseMatch',
                name: '企业专利双向匹配',
                cnName: '企业-专利双向匹配',
                curType: 'sub',
                id: '2',
                meta: {
                    type: '',
                    topActive: '1',
                    ppath: ''
                },
                component: () => import('../views/patentEnterpriseMatch/index.vue')
            },
            {
                path: '/patentEnterpriseMatchNew',
                name: '企业-专利双向匹配',
                cnName: '企业-专利双向匹配',
                curType: 'sub',
                id: '2',
                meta: {
                    type: '',
                    topActive: '1',
                    ppath: ''
                },
                component: () => import('../views/patentEnterpriseMatch/indexNew.vue')
            },
            {
                path: '/technicalRelease',
                name: '技术需求发布',
                cnName: '技术需求发布',
                curType: 'sub',
                id: '3',
                meta: {
                    type: '',
                    topActive: '1',
                    ppath: ''
                },
                component: () => import('../views/technicalRelease/index.vue')
            },
            {
                path: '/patentMapGraph',
                name: '专利导航图谱',
                cnName: '专利导航图谱',
                curType: 'sub',
                id: '4',
                meta: {
                    type: '',
                    topActive: '1',
                    ppath: ''
                },
                // component: () => import('../views/patentMapGraph/index.vue')
                component: patentMapGraph
            },
            {
                path: '/industryChainGraph',
                name: '产业链图谱',
                cnName: '产业链图谱',
                curType: 'sub',
                id: '5',
                meta: {
                    type: 'home',
                    topActive: '1'
                },
                // component: () => import('../views/industryChainGraph/index.vue')
                component: industryChainGraph
            },
            {
                path: '/patentColleges',
                name: '院校存量专利盘点',
                cnName: '院校存量专利盘点',
                curType: 'sub',
                id: '6',
                meta: {
                    type: '',
                    topActive: '1'
                },
                component: () => import('../views/patentColleges/index.vue')
            },
            {
                path: '/technologyMap',
                name: '科技成果转化地图',
                cnName: '科技成果转化地图',
                curType: 'sub',
                id: '7',
                meta: {
                    type: '',
                    topActive: '1'
                },
                component: () => import('../views/technologyMap/index.vue')
            },
            {
                path: '/patent',
                name: '专利',
                curType: 'main',
                cnName: '专利',
                id: '9',
                meta: {
                    type: 'side',
                    subName: '专利',
                    keepAlive: true
                },
                component: () => import('../views/patent/index.vue')
            },
            {
                path: '/patentDetail',
                name: '专利详情',
                cnName: '专利详情',
                curType: 'sub',
                pId: '9',
                id: '10',
                meta: {
                    type: 'side',
                    subName: '专利',
                    detailName: '专利详情',
                    ppath: '/patent',
                },
                component: () => import('../views/patentDetail/index.vue')
            },
            {
                path: '/enterprise',
                name: '企业',
                cnName: '企业',
                curType: 'main',
                id: '11',
                meta: {
                    type: 'side',
                    subName: '企业',
                },
                component: () => import('../views/enterprise/index.vue')
            },
            {
                path: '/enterpriseDetail',
                name: '企业详情',
                cnName: '企业详情',
                curType: 'sub',
                pId: '11',
                id: '12',
                meta: {
                    type: 'side',
                    subName: '企业',
                    detailName: '企业详情',
                    ppath: '/enterprise',
                },
                component: () => import('../views/enterpriseDetail/index.vue')
            },
            {
                path: '/inventor',
                name: '发明人',
                cnName: '发明人',
                curType: 'main',
                id: '13',
                meta: {
                    type: 'side',
                    subName: '发明人',
                },
                component: () => import('../views/inventor/index.vue')
            },
            {
                path: '/inventorDetail',
                name: '发明人详情',
                cnName: '发明人详情',
                curType: 'sub',
                pId: '13',
                id: '14',
                meta: {
                    type: 'side',
                    subName: '发明人',
                    detailName: '发明人详情',
                    ppath: '/inventor',
                },
                component: () => import('../views/inventorDetail/index.vue')
            },
            {
                path: '/technical',
                name: '技术需求',
                cnName: '技术需求',
                curType: 'main',
                id: '15',
                meta: {
                    type: 'side',
                    subName: '技术需求',
                },
                component: () => import('../views/technical/index.vue')
            },
            {
                path: '/technicalDetail',
                name: '技术需求详情',
                cnName: '技术需求详情',
                curType: 'sub',
                pId: '15',
                id: '16',
                meta: {
                    type: 'side',
                    subName: '技术需求',
                    detailName: '技术需求详情',
                    ppath: '/technical',
                },
                component: () => import('../views/technicalDetail/index.vue')
            },
            {
                path: '/investor',
                name: '投资机构',
                cnName: '投资机构',
                curType: 'main',
                id: '17',
                meta: {
                    type: 'side',
                    subName: '投资机构',
                },
                component: () => import('../views/investor/index.vue')
            },
            {
                path: '/investorDetail',
                name: '投资机构详情',
                cnName: '投资机构详情',
                curType: 'sub',
                pId: '17',
                id: '18',
                meta: {
                    type: 'side',
                    subName: '投资机构',
                    detailName: '投资机构详情',
                    ppath: '/investor',
                },
                component: () => import('../views/investorDetail/index.vue')
            },
            {
                path: '/aiRecommendation',
                name: '智能推荐',
                cnName: '智能推荐',
                curType: 'sub',
                pId: '3',
                id: '19',
                meta: {
                    type: '',
                    topActive: '1',
                    active: '技术需求发布'
                },
                component: () => import('../views/aiRecommendation/index.vue')
            },
            {
                path: '/dataList',
                name: '数据列表',
                cnName: '数据列表',
                id: '20',
                meta: {
                    type: '',
                    topActive: '1',
                    active: '技术需求发布'
                },
                component: () => import('../views/technicalRelease/DataList.vue')
            },
        ]
    },
    {
        path: '/user',
        name: 'user',
        cnName: '用户',
        curType: 'user',
        component: UserLayout,
        redirect: '/personal',
        children: [
            {
                path: '/personal',
                name: '用户信息',
                cnName: '用户信息',
                id: '21',
                curType: 'user',
                meta: {
                    type: 'side',
                    pname: 'user',
                    detailName: '用户设置'
                },
                component: () => import('../views/personal/indexNew.vue')
            },
            {
                path: '/history',
                name: '历史记录',
                cnName: '历史记录',
                id: '22',
                curType: 'user',
                meta: {
                    type: 'side',
                    pname: 'user',
                    detailName: '历史记录'
                },
                component: () => import('../views/history/index.vue')
            },
            {
                path: '/favorites',
                name: '我的收藏',
                cnName: '我的收藏',
                id: '23',
                curType: 'user',
                meta: {
                    type: 'side',
                    pname: 'user',
                    detailName: '我的收藏'
                },
                component: () => import('../views/favorites/index.vue')
            },
            {
                path: '/myNeed',
                name: '我的需求',
                cnName: '我的需求',
                id: '24',
                curType: 'user',
                meta: {
                    type: 'side',
                    pname: 'user',
                    detailName: '我的需求'
                },
                component: () => import('../views/myNeed/index.vue')
            }
        ]
    },
    {
        path: '/collegePatentDashboard',
        name: 'collegePatentDashboard',
        cnName: '院校专利成果转化仪表盘',
        curType: 'sub',
        redirect: '/technologyMap',
        children: [
            {
                path: '/collegePatentDashboard',
                name: '院校专利成果转化仪表盘',
                cnName: '院校专利成果转化仪表盘',
                curType: 'sub',
                id: '8',
                meta: {
                    type: '',
                    topActive: '1'
                },
                component: () => import('../views/collegePatentDashboard/index.vue')
            },
        ]
    },
];

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    // history: createWebHistory(process.env.BASE_URL),
    routes
});

function setHomeTopActive(to) {
    if (to.meta.topActive && to.meta.topActive === '1') {
        const subName = to.meta.subName ? to.meta.subName : '应用场景';
        const subNameDetail = to.meta.detailName ? to.meta.detailName : to.name;
        store.commit('setSubName', {subName, subNameDetail});
        if (subNameDetail === '企业-专利双向匹配') {
            store.commit('setMatchCurTab', '专利匹配企业')
        }
        const active = to.meta.active && to.meta.active !== '' ? to.meta.active : to.name;
        store.commit('setHomeTopActive', active);
    } else {
        store.commit('setHomeTopActive', 'clear');
    }
}

function setSubName(to) {
    if (to.meta.subName) {
        const subName = to.meta.subName;
        const subNameDetail = to.meta.detailName ? to.meta.detailName : undefined;
        store.commit('setSubName', {subName, subNameDetail});
    }
}

function setUser(to) {
    if (to.meta.pname && to.meta.pname === 'user') {
        const subName = to.meta.detailName ? to.meta.detailName : undefined;
        store.commit('setSubName', {subName});
        store.commit('setUserPath', to.path);
    }
}

router.beforeEach((to, from, next) => {
    const isLogin = sessionStorage.getItem('token');
    const showSearchList = ['/patent','/enterprise','/inventor','/technical']
    if (to.name === 'login') {
        next();
    } else if (isLogin) {
        if (showSearchList.includes(to.path)) {
            store.commit('setSwitchSearch', '1');
        } else {
            store.commit('setSwitchSearch', '0');
        }
        if (to.meta.type && to.meta.type === 'home') {
            store.commit('setCurrentPath', '/home');
        } else if (to.meta.ppath) {
            store.commit('setCurrentPath', to.meta.ppath);
        } else {
            store.commit('setCurrentPath', to.path);
        }
        setHomeTopActive(to);
        setSubName(to);
        setUser(to);

        next();
    } else {
        store.commit('setHomeTopActive', 'clear');
        next('/');
    }
});

export default router;
