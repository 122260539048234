<template>
  <InfoCard showExport exportName="导出专利分析报告" @handleExport="handleExport" :downImg="state.Multidiscount"
            exportPath="/patents/patents/exportPNMReport" :export-params="exportParams">
    <template #info>
      <div class="graph_map" ref="gmap">
        <InnerPageMenu @changeSelect="changeSelect" ref="innerPageMenuRef"></InnerPageMenu>
        <div class="right">
          <div class="block_item">
            <el-tabs v-model="activeName" @tab-change="handleChange">
              <el-tab-pane label="可视化分析" name="可视化分析"></el-tab-pane>
              <el-tab-pane label="专利列表" name="专利列表"></el-tab-pane>
            </el-tabs>
            <div ref="rpage" style="min-height: 100%" v-if="activeName=='可视化分析'">
              <BlockTitle name="可视化分析"></BlockTitle>
              <el-row :gutter="20" style="margin-bottom: 1.42rem">
                <el-col :span="16">
                  <div class="line">
                    <div class="top">
                      <span class="title">专利申请趋势</span>
                      <ASelect
                          v-model="selectVal1"
                          placeholder="全部"
                          :options="soptions1"
                          width="120px"
                          isRadius
                          @change="changeYear1"
                      >
                      </ASelect>
                    </div>
                    <div v-if="chart1" :style="setHeight('chart')" id="chartMultidiscount">
                      <EchartTemp :options="state.options1"></EchartTemp>
                    </div>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="line">
                    <div class="top">
                      <span class="title">发明人top10</span>
                    </div>
                    <div v-if="state.options2.series" :style="setHeight('chart')">
                      <EchartTemp :options="state.options2"></EchartTemp>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="20">
                <el-col :span="8">
                  <div class="line">
                    <div class="top">
                      <span class="title">专利申请类型</span>
                      <ASelect
                          v-model="selectVal2"
                          placeholder="全部"
                          :options="soptions1"
                          width="120px"
                          isRadius
                          @change="changeYear2"
                      >
                      </ASelect>
                    </div>
                    <div v-if="isShow3" :style="setHeight('chart')">
                      <EchartTemp :options="state.options3"></EchartTemp>
                    </div>
                    <el-empty v-else description="暂无专利申请类型数据"></el-empty>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="line">
                    <div class="top">
                      <span class="title">法律事件</span>
                      <ASelect
                          v-model="selectVal3"
                          placeholder="全部"
                          :options="soptions1"
                          width="120px"
                          isRadius
                          @change="changeYear3"
                      >
                      </ASelect>
                    </div>
                    <div v-if="isShow4" :style="setHeight('chart')">
                      <EchartTemp :options="state.options4"></EchartTemp>
                    </div>
                    <el-empty v-else description="暂无法律事件数据"></el-empty>
                  </div>
                </el-col>
                <el-col :span="8">
                  <div class="line">
                    <div class="top">
                      <span class="title">专利状态</span>
                      <ASelect
                          v-model="selectVal4"
                          placeholder="全部"
                          :options="soptions1"
                          width="120px"
                          isRadius
                          @change="changeYear4"
                      >
                      </ASelect>
                    </div>
                    <div v-if="isShow5" :style="setHeight('chart')">
                      <EchartTemp :options="state.options5"></EchartTemp>
                    </div>
                    <el-empty v-else description="暂无专利状态数据"></el-empty>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div v-if="activeName=='专利列表'">
              <BlockTitle name="专利列表" :count="state.tableDataTotal" firstName="共有"
                          lastName="条专利"></BlockTitle>
              <TablePage
                  :columnData="columnData"
                  max-height="calc(100vh - 360px)"
                  :tableDataTotal="state.tableDataTotal"
                  :tableData="state.tableData"
                  :current-page="state.pageNum"
                  @changePage="changePage"
                  @rowClick="rowClick"
              >
              </TablePage>
            </div>
          </div>
        </div>
      </div>
    </template>
  </InfoCard>
  <div ref="exportref" v-if="isExport">
    <patentMapGraphExport></patentMapGraphExport>
  </div>
</template>

<script setup>
import {nextTick, onMounted, reactive, ref} from 'vue';
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {ElMessage} from "element-plus";
import InfoCard from '@/components/InfoCard.vue';
import EchartTemp from '@/components/EchartTemp.vue';
import ASelect from '@/components/ASelect.vue';
import TablePage from '@/components/TablePage.vue';
import InnerPageMenu from '@/components/InnerPageMenu.vue';
import BlockTitle from '@/components/BlockTitle.vue';
import {options1, options2, options3, options4, options5} from './chartsOptions';
import {legalEvents, years} from '@/untils/selectOptions';
import {
  getGraph1,
  getGraph2,
  getGraph3,
  getGraph4,
  getGraph5,
  getGraphList
} from '@/server/patent';
import {groupByName, downloadPDF, showLoading, hideLoading} from "@/untils";
import AEmpty from "@/components/AEmpty.vue";
import patentMapGraphExport from '../exportPages/patentMapGraphExport.vue'
import html2canvas from "html2canvas";

const toRoute = useRouter();
const globalStore = useStore();

const college = globalStore.state.globalName

const selectVal1 = ref('');
const selectVal2 = ref('');
const selectVal3 = ref('');
const selectVal4 = ref('');
const activeName = ref('可视化分析');
const boxHeight = ref('');
const gmap = ref(null);
const chart1 = ref(false)
const rpage = ref(null)
const exportref = ref(null)
const isExport = ref(false)
const innerPageMenuRef = ref(null)
const exportParams = reactive({
  id: '',
}) // 导出参数

const soptions1 = years.map(item => {
  return {
    label: item,
    value: item
  };
});

const soptions3 = legalEvents.map(item => {
  return {
    label: item,
    value: item
  };
});

const columnData = [
  {
    prop: 'gkh',
    label: '公开号'
  },
  {
    prop: 'title',
    label: '专利标题'
  },
  {
    prop: 'zlr',
    label: '申请人'
  },
  {
    prop: 'fmr',
    label: '发明人'
  },
  {
    prop: 'zlqr',
    label: '专利权人'
  },
  {
    prop: 'gkrq',
    label: '公开日'
  },
  {
    prop: 'zlType',
    label: '专利类型'
  },
  {
    prop: 'zlStatus',
    label: '专利状态'
  },
  {
    prop: 'flsj',
    label: '法律事件'
  }
];

const isShow5 = ref(false)
const isShow4 = ref(false)
const isShow3 = ref(false)
const state = reactive({
  tableDataTotal: 0,
  tableData: [],
  options1: {},
  options2: {},
  options3: {},
  options4: {},
  options5: {},
  gmjjfl: '高端装备制造',
  typeIds: '',
  Multidiscount: null,
  pageNum: 1
});
const handleExport = (name) => {
  // showLoading()
  // isExport.value = true
  // setTimeout(() => {
  //   if (exportref.value) {
  //     downloadPDF(exportref.value, name, () => {
  //       isExport.value = false
  //       hideLoading()
  //     }, 'hideLoad')
  //   }
  // }, 3000)
}
const setHeight = (type) => {
  return {
    width: '100%',
    height: `300px`
  };
};
const updateWindowHeight = () => {
  const wh = window.innerHeight;
  if (gmap.value) {
    const of = gmap.value.offsetTop;
    const subh = wh - of - 27;
    boxHeight.value = `${subh}px`;
  }
};

const getImg = (id,type) => {
  setTimeout(() => {
    html2canvas(document.getElementById(id)).then((canvas) => {
      const image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream");
      state[type] = image.split('base64,')[1]
    })
  },1500)
}

const rowClick = (row) => {
  toRoute.push({path: '/patentDetail', query: {paramId: row.id}});
};
const handleChange = (val) => {
  activeName.value = val
  if (val === '专利列表') {
    initData6(1, 10)
  } else {
    nextTick(() => {
      initData1('')
      initData2()
      initData3('')
      initData4('')
      initData5('', '')
    })
  }
}
const setYear = (value) => {
  let year;
  switch (value) {
    case'今年':
      year = '1';
      break;
    case'近两年':
      year = '2';
      break;
    case'近三年':
      year = '3';
      break;
    case'近四年':
      year = '4';
      break;
    case'近五年':
      year = '5';
      break;
    case'近六年':
      year = '6';
      break;
    case'近七年':
      year = '7';
      break;
    case'近八年':
      year = '8';
      break;
    case'近九年':
      year = '9';
      break;
    case'近十年':
      year = '10';
      break;
    default:
      year = '';
  }
  return year
};
const changeYear1 = (val) => {
  const year = setYear(val)
  initData1(year)
}
const changeYear2 = (val) => {
  const year = setYear(val)
  initData3(year)
}
const changeYear3 = (val) => {
  const year = setYear(val)
  initData4(year)
}
const changeYear4 = (val) => {
  const year = setYear(val)
  initData5(year, '')
}
const changeSelect = (value) => {
  state.gmjjfl = value.name
  state.typeIds = value.id
  selectVal1.value = ''
  selectVal2.value = ''
  selectVal3.value = ''
  selectVal4.value = ''
  if (activeName.value === '专利列表') {
    initData6(1, 10)
  } else {
    initData1('')
    initData2('')
    initData3('')
    initData4('')
    initData5('', '')
  }
  exportParams.id = value.id
}
const initData1 = async (year) => {
  const res1 = await getGraph1(year, state.gmjjfl, localStorage.getItem('school_deptName') || '',state.typeIds)
  if (res1.code === 200 && res1.data) {
    const {data} = res1
    const years = [...new Set(data.map(item => item.year))]
    const types = [...new Set(data.map(item => item.zlType))]
    state.options1 = {...options1};
    const arr = groupByName(data, 'zlType')
    const sData = arr.map(item => {
      if (item.length > 0) {
        return {
          name: item[0].zlType,
          type: 'line',
          // symbol: 'none',
          lineStyle: {
            width: 2
          },
          data: item.map(item => item.num)
        };
      }
    });
    state.options1.xAxis[0].data = years;
    state.options1.legend.data = types;
    state.options1.series = [...sData];
    chart1.value = true
    getImg('chartMultidiscount','Multidiscount')
  }
}
const initData2 = async () => {
  const res2 = await getGraph2(state.gmjjfl, localStorage.getItem('school_deptName') || '',state.typeIds)
  if (res2.code === 200 && res2.data) {
    const {data} = res2
    state.options2 = {...options2};
    state.options2.yAxis[0].data = data.map(item => item.inventor);
    state.options2.yAxis[1].data = data.map(item => item.num);
    state.options2.series[0].data = data.map(item => item.num);
  }
}
const initData3 = async (year) => {
  const res3 = await getGraph3(year, state.gmjjfl, localStorage.getItem('school_deptName') || '',state.typeIds)
  if (res3 && res3.code === 200 && res3.data) {
    const {data} = res3
    isShow3.value = data && data.length > 0
    state.options3 = {...options3};
    state.options3.series[0].data = data.map(item => {
      return {
        name: item.zlType,
        value: item.zlNum
      };
    });
  }
}
const initData4 = async (year) => {
  const res4 = await getGraph4(year, state.gmjjfl, localStorage.getItem('school_deptName') || '',state.typeIds)
  if (res4 && res4.code === 200 && res4.data) {
    const {data} = res4
    isShow4.value = data && data.length > 0
    state.options4 = {...options4};
    state.options4.series[0].data = data.map(item => {
      return {
        name: item.flsj,
        value: item.zlNum
      };
    });
  }
}
const initData5 = async (year, school) => {
  const res5 = await getGraph5(year, school, state.gmjjfl, localStorage.getItem('school_deptName') || '',state.typeIds)
  if (res5.code === 200 && res5.data) {
    const {data} = res5
    if (data.length > 0) {
      state.options5 = {...options5};
      state.options5.series[0].data = data.map(item => {
        return {
          name: item.zlStatus,
          value: item.zlNum
        };
      });
    }
    isShow5.value = data.length > 0
  }
};
const initData6 = async (pageNum, pageSize) => {
  const res6 = await getGraphList({pageNum, pageSize, gmjjfl: state.gmjjfl, id: state.typeIds,college: localStorage.getItem('school_deptName')})
  if (res6) {
    // console.log(res6)
    const {rows, total} = res6
    state.tableData = rows
    state.tableDataTotal = total || 0
  } else {
    state.tableData = []
    state.tableDataTotal = 0
    ElMessage.error(res6.msg);
  }
}
const changePage = (value) => {
  state.pageNum = value
  initData6(value, 10);
};

onMounted(() => {
  // initData1('9')
  // initData2()
  // initData3('3')
  // initData4('3')
  // initData5('9', '')
  updateWindowHeight();
  window.addEventListener('resize', updateWindowHeight)
});

</script>

<style scoped lang="less">
.graph_map {
  display: flex;
  justify-content: space-between;
  margin-top: 1.42rem;

  .right {
    flex: 1;
    height: 100%;

    .block_item {
      width: 100%;
      height: calc(100vh - 230px);
      overflow-y: auto;
    }

    .line {
      background: #FFFFFF;
      border-radius: 10px;
      border: 1px solid #E9E9E9;
      min-height: 414px;
      padding: 1.42rem 0;
      box-sizing: border-box;
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 1.42rem;

      .title {
        font-weight: bold;
        font-size: 1.1em;
        color: #1A202C;
      }
    }
  }
}
</style>
