<template>
  <el-form
      ref="loginForm"
      style="max-width: 600px"
      :model="ruleForm"
      status-icon
      :rules="rules"
      label-width="0"
      class="demo-ruleForm"
  >
    <el-form-item label="" prop="phone" class="margin_b36">
      <el-input class="login_input login_input_h" v-model="ruleForm.phone" placeholder="请输入手机号码"
                autocomplete="off"/>
    </el-form-item>
    <el-form-item label="" prop="code" class="code_item margin_b36">
      <el-input class="login_input login_input1 login_input_h" v-model="ruleForm.code" placeholder="请输入验证码"
                autocomplete="off"/>
      <el-button @click="setCode" type="primary" class="send_code login_input_h">
        {{ countdown > 0 ? `倒计时(${countdown})` : '发送验证码' }}
      </el-button>
    </el-form-item>
    <el-form-item style="margin-top: 5rem">
      <el-button class="login_btn login_input_h" type="primary" @click="submitForm(loginForm)">立即登录
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script setup>
import {reactive, ref} from 'vue';
import {useRouter} from 'vue-router';
import {ElMessage, ElMessageBox} from 'element-plus';
import {setPhoneCode, checkPhoneCode} from '@/server/login';
import {useStore} from "vuex";
import {setCookie} from "@/untils/loginTool";

const router = useRouter();
const globalStore = useStore();
const isCanClick = ref(false);
const countdown = ref(0);
const loginForm = ref();

const ruleForm = reactive({
  phone: '',
  code: '',
});

const flag = ref(2)
// const flag = ref(3)

const validatePhone = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('手机号码不能为空'));
    isCanClick.value = false;
  } else if (!/^1\d{10}$/.test(value)) {
    callback(new Error('手机号码输入不正确'));
    isCanClick.value = false;
  } else {
    isCanClick.value = true;
    callback();
  }
};

const validateCode = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('验证码不能为空'));
  } else if (!/^\d{4}$/.test(value)) {
    callback(new Error('验证码输入不正确'));
  } else {
    callback();
  }
};

const rules = reactive({
  phone: [{validator: validatePhone, trigger: 'blur'}],
  code: [{validator: validateCode, trigger: 'blur'}]
});

const setCode = async () => {
  if (!isCanClick.value) return ElMessage.error('请输入正确的手机号码');
  if (countdown.value > 0) {
    ElMessage({
      message: '验证码已发送，请勿重复操作',
      type: 'warning'
    });
    return; // 如果倒计时未结束，则不执行发送操作
  }
  if (!ruleForm.phone) return;
  const result = await setPhoneCode(ruleForm.phone);
  if (result.code !== 200) return ElMessage.error(result.msg);
  if (result.code === 200) {
    ElMessage({
      message: '验证码已发送至手机，请注意查收',
      type: 'success'
    });
  }
  countdown.value = 60;
  const intervalId = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value -= 1;
    } else {
      clearInterval(intervalId);
    }
  }, 1000);
};

const submitForm = (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid) => {
    if (valid) {
      const {
        code,
        msg
      } = await checkPhoneCode(ruleForm.phone, ruleForm.code, flag.value, sessionStorage.getItem('deviceId'));
      if (code === 200) {
        flag.value = 2
        // console.log(result)
        globalStore.commit('setCurrentUser', ruleForm.phone)
        sessionStorage.setItem('token', msg);
        // setCookie("token", msg,7)
        router.push('/root');
      } else if (code == 10000) {
        flag.value = 3
        ElMessageBox.alert('此账号已在其他设备登录，是否在其他设备退出，并在此设备登录?', '信息', {
              confirmButtonText: '确定',
              showClose: true,
            }
        ).then(()=> {
          submitForm(loginForm.value)
        })
      } else {
        ElMessage.error(msg || '登录失败');
      }
    } else {
      return false;
    }
  });
};

const resetForm = (formEl) => {
  if (!formEl) return;
  formEl.resetFields();
};
</script>

<style scoped lang="less">
.demo-ruleForm {
  padding-top: 2.14rem; //30

  /deep/ .el-input .el-input__icon {
    font-size: 16px;
    color: #86909C;
  }
}

.margin_b36 {
  margin-bottom: 2.57rem; //36
}

.login_input1 {
  width: 280px !important;
}

.login_input {
  width: 380px;
  background: #F2F3F5;
  border-radius: 4px;
  border: 1px solid #E9E9E9;

  /deep/ .el-input__wrapper {
    background-color: transparent;
    box-shadow: none;
  }

  /deep/ .el-input__inner {
    color: #171B1E;
  }
}

.code_item {
  /deep/ .el-input {
    flex: 1;
  }

  .send_code {
    width: 100px;
    //height: 40px;
    margin-left: 10px;
    text-align: center;
    color: white;
    //line-height: 40px;
  }
}

.login_btn {
  width: 100%;
  //height: 40px;
  font-weight: bold;
  letter-spacing: 2px
}
</style>
