<template>
  <el-form ref="loginForm" :model="ruleForm" status-icon :rules="rules" label-width="0" class="demo-ruleForm">
    <el-form-item label="" prop="username" class="margin_b36">
      <el-input class="login_input login_input_h" v-model="ruleForm.username" placeholder="请输入手机号"
                autocomplete="off" clearable>
        <template #prefix>
          <img class="prefix" :src="userIconRef">
        </template>
      </el-input>
    </el-form-item>
    <el-form-item label="" prop="password" class="margin_b36">
      <el-input
          v-model="ruleForm.password"
          type="password"
          autocomplete="new-password"
          class="login_input login_input_h"
          autoComplete="off"
          show-password
          placeholder="请输入密码"
          clearable
      >
        <template #prefix>
          <img class="prefix" :src="passIcon">
        </template>
      </el-input>
    </el-form-item>
    <el-form-item label="" prop="verification" class="verification_box">
      <el-input
          :prefix-icon="Briefcase"
          v-model="ruleForm.code"
          autocomplete="off"
          clearable
          placeholder="请输入验证码"
          class="login_input login_input1 login_input_h"
      ></el-input>
      <img
          v-if="identifyingCode"
          :src="identifyingCode"
          alt="验证码"
          class="identifying_code login_input_h"
          @click="getVerifyCodeImage()"
      >
    </el-form-item>
    <div class="remember_forget">
      <el-checkbox v-model="savePass" label="记住密码" size="large"/>
      <div class="forget">忘记密码？</div>
    </div>
    <el-form-item>
      <el-button class="login_btn login_input_h" type="primary" @click="submitForm(loginForm)">立即登录
      </el-button>
    </el-form-item>
    <div class="login_f_tip">
      登录视为您已阅读并同意<span class="l_text">服务条款</span>和<span class="l_text">隐私政策</span>
    </div>
  </el-form>
</template>

<script setup>
import {reactive, ref, onMounted} from 'vue';
import {useRouter} from 'vue-router';
import {ElMessage, ElMessageBox} from 'element-plus';
import {UserFilled, Briefcase} from '@element-plus/icons-vue';
import {getCodeImage, login} from '@/server/login';
import userIcon from '@/assets/kzb/icon-Vector.png';
import passIcon from '@/assets/kzb/icon-password.png';

import {
  setCookie,
  getCookie
} from '@/untils/loginTool'
import {useStore} from "vuex";


const router = useRouter();
const globalStore = useStore();
const loginForm = ref();
const savePass = ref(true);
const identifyingCode = ref(null);
const userIconRef = ref(userIcon);

const ruleForm = reactive({
  username: '',
  password: '',
  code: '',
  uuid: ''
});

const rules = reactive({
  username: [{required: true, message: '请输入用户名称', trigger: 'blur'}],
  password: [{required: true, message: '请输入用户密码', trigger: 'blur'}],
  code: [{required: true, message: '请输入验证码', trigger: 'blur'}]
});

const submitForm = (formEl) => {
  if (!formEl) return;
  formEl.validate((valid) => {
    if (valid) {
      goLogin(ruleForm);
    } else {
      return false;
    }
  });
};
const getVerifyCodeImage = async () => {
  const result = await getCodeImage();
  if (result && result.code === 200) {
    const {img, uuid} = result;
    ruleForm.uuid = uuid;
    // identifyingCode.value = `data:image/jpg;base64,${btoa(new Uint8Array(img.slice(3)).reduce((data, byte) => data + String.fromCharCode(byte), ''))}`;
    identifyingCode.value = `data:image/jpg;base64,${img}`;
  }
};

const goLogin = async (valid) => { // flag 2为第一次登录 3为确定重新登录
  const result = await login({flag: valid.flag == 3 ? valid.flag : 2,deviceId: sessionStorage.getItem('deviceId'),...valid});
  // const result = await login({flag: 3,deviceId: sessionStorage.getItem('deviceId'),...valid});
  if (result.code === 200) {
    globalStore.commit('setCurrentUser', valid.nickName)
    sessionStorage.setItem('token', result.token);
    // setCookie("token", result.token,7)
    setUserInfo()
    router.push('/root');
  } else if (result.code === 10000) {
    // getVerifyCodeImage();
    ElMessageBox.alert('此账号已在其他设备登录，是否在其他设备退出，并在此设备登录?', '信息', {
          confirmButtonText: '确定',
          showClose: true,
        }
    ).then(() => {
      const params = {flag: 3,...valid}
      // params.code && delete params.code
      goLogin(Object.assign(params))
    })
  } else {
    getVerifyCodeImage();
    ElMessage.error(result.msg || '登录失败');
  }
};
const setUserInfo = () => {
  //判断用户是否勾选记住密码,如果勾选,向cookie中储存登录信息,如果没有勾选,储存的信息为空
  if (savePass.value) {
    //将手机号存入cookie,过期时间为7天
    setCookie("username", ruleForm.username, 7);
    //base64加密密码
    // let authPwd = window.atob(ruleForm.password);
    //将加密后密码存入cookie,过期时间为7天
    setCookie("authPwd", ruleForm.password, 7);
  } else {
    // 清空key值为authPhone的token
    setCookie("username", "", 0);
    // 清空key值为authPwd的token
    setCookie("authPwd", "", 0);
  }
}
const resetForm = (formEl) => {
  if (!formEl) return;
  formEl.resetFields();
};
onMounted(() => {
  getVerifyCodeImage();
  let authPhone = getCookie("username");
  //获取解密后的密码
  // let authPwd = window.btoa(getCookie("authPwd"));
  let authPwd = getCookie("authPwd")
  // 页面加载时，检查 localStorage 中是否有保存的用户名和密码
  if (authPhone) {
    ruleForm.username = authPhone;
    ruleForm.password = authPwd;
    savePass.value = true;
  } else {
    savePass.value = false;
  }
});
</script>

<style scoped lang="less">
.demo-ruleForm {
  padding-top: 2.14rem; //30

  /deep/ .el-input .el-input__icon {
    font-size: 16px; //16
    color: #86909C;
  }
}

.margin_b36 {
  margin-bottom: 2.57rem; //36
}

.remember_forget {
  display: flex;
  height: 2.85rem; //40
  align-items: center;
  justify-content: space-between;
  //font-size: 1.28rem;
  font-size: 14px;
  margin-bottom: 2.85rem; //40

  .forget:hover {
    color: #2D6CFF;
    cursor: pointer;
  }
}

.login_input {
  //height: 40px; //40
  background: #F2F3F5;
  border-radius: 4px;
  border: 1px solid #E9E9E9;
  width: 380px;

  /deep/ .el-input__wrapper {
    background-color: transparent;
    box-shadow: none;
  }

  /deep/ .el-input__inner {
    color: #171B1E;
  }
}

.login_input1 {
  width: 230px !important;
}

.login_btn {
  width: 380px;
  //height: 40px; //40
  font-weight: bold;
  letter-spacing: 2px
}

.login_f_tip {
  //font-size: 1.28rem;
  font-size: 14px;
  color: #717579;
  //font-size: min(1.28rem, 14px);
}

.l_text {
  color: #2D6CFF;
}

.verification_box {
  margin-bottom: 5px;

  /deep/ .el-form-item__content {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
}

.identifying_code {
  width: 150px; //150
  border: 1px solid #f1f1f1;
  margin-left: 5px;
  background: #f1f1f1;
  //height: 40px; //40
  box-sizing: border-box;
}

.identifying_code:hover {
  cursor: pointer;
}

.prefix {
  width: 13px;
}
</style>
