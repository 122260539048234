<template>
  <el-breadcrumb>
    <el-breadcrumb-item @click="changePage('home',globalStore.state.subName)">首页</el-breadcrumb-item>
    <el-breadcrumb-item>
      {{ globalStore.state.subName }}
    </el-breadcrumb-item>
    <el-breadcrumb-item>
      {{ globalStore.state.subNameDetail }}
    </el-breadcrumb-item>
    <el-breadcrumb-item v-if="globalStore.state.subLastNameDetail">
      {{ globalStore.state.subLastNameDetail }}
    </el-breadcrumb-item>
    <el-breadcrumb-item v-if="globalStore.state.subLastNameDetail2">
      {{ globalStore.state.subLastNameDetail2 }}
    </el-breadcrumb-item>
  </el-breadcrumb>
</template>

<script setup>
import {useStore} from 'vuex';
import {useRouter} from 'vue-router';

const globalStore = useStore();
const toRoute = useRouter();

const changePage = (type, name) => {
  return;
  if (type === 'home') {
    toRoute.push('/root');
  } else {
    if (name === '应用场景') return
    toRoute.push({name: name});
  }
};
</script>

<style scoped lang="less">
/deep/ .el-breadcrumb__item:hover {
  //cursor: pointer;
  //opacity: 0.8;
}
</style>
