// 技术领域
export const technicalField = ['电子信息', '新能源与节能', '生物与新医药', '先进制造与自动化', '航空航天', '资源与环境', '高新技术服务', '建筑业', '现代农业', '其他'];
//职称
export const jobTitle = [
    {label: '正高',value: '教授,研究员,教授级高级工程师'},
    {label: '副高',value: '副教授,副研究员,高级工程师'},
    {label: '中级',value: '讲师,工程师,助理研究员'},
    // {label: '其他',value: '其他'}
]
// 所属学院
export const colleges = ['机电工程学院', '机械工程学院', '材料科学与工程学院', '化学与化工学院', '计算机科学与技术学院', '数学学院', '经济与管理学院', '人文社会科学学院', '体育学院', '艺术学院', '软件学院', '环境与安全工程学院', '能源与动力工程学院', '马克思主义学院', '航空宇航学院', '半导体与物理学院', '继续教育学院', '电力学院(朔州校区)'];
// 资质荣誉
export const honors = ['院士', '杰青', '长江学者', '千人计划', '优青', '青年长江', '青千', '拔尖人才', '万人计划', '百人计划'];
// 合作方式
export const modes = ['委托开发', '合作开发', '专利转让', '专利许可', '技术咨询', '技术培训', '技术服务', '其他'];
// 费用预算
export const costBudget = ['0-1万', '1-10万', '10-50万', '50-100万', '100-500万'];
// 专利类型
export const patentType = ['发明授权', '发明申请', '外观设计', '实用新型', '其他'];
// 专利状态
export const patentStatus = ['有效', '审查中'];
// 企业类别
export const qyCategory = [
    {value: 'isfive',label: '中国500强'},
    {value: 'quoted_company',label: '上市企业'},
    {value: 'unicorn',label: '独角兽企业'},
    {value: 'high_new',label: '高新技术企业'},
    {value: 'dengl',label: '瞪羚企业'},
    {value: 'zjtx_gjjxjr',label: '专精特新“小巨人”企业'},
    {value: 'zjtg',label: '专精特新中小企业'},
    {value: 'tbe',label: '科技型中小企业'},
    {value: 'innovate',label: '创新型中小企业'},
];
// 企业重新排名
export const qySort = ['企业科创能力', '企业技术合作'];
// 年份
export const years = ['全部','今年', '近两年', '近三年', '近四年', '近五年', '近六年', '近七年', '近八年', '近九年', '近十年'];
// 法律事件
export const legalEvents = ['转让', '许可', '质押', '诉讼', '保全', '变更'];

export const newLegalEvents = ['授权', '未缴年费', '实质审查', '驳回', '撤回', '避重放弃', '公开', '放弃', '期限届满'];
