import {createStore} from 'vuex';
import {logout} from '@/server/login'

// 判断对象是否为空
function isEmptyObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            return false;
        }
    }
    return true;
}

export default createStore({
    state: {
        isDetail: false,
        breadcrumbs: [],
        upPath: '',
        currentPath: sessionStorage.getItem('currentPath') || '/home',
        currentUser: '',
        subName: sessionStorage.getItem('subName') || null,
        subNameDetail: sessionStorage.getItem('subNameDetail') || null,
        subLastNameDetail: sessionStorage.getItem('subLastNameDetail') || null,
        subLastNameDetail2: sessionStorage.getItem('subLastNameDetail2') || null,
        isHideSearch: sessionStorage.getItem('isHideSearch') ? sessionStorage.getItem('isHideSearch') : '0',
        homeTopActive: sessionStorage.getItem('homeTopActive') || '',
        searchWord: {
            keyWord: ''
        },
        loadingCount: 0,
        userPath: sessionStorage.getItem('userPath') || '/personal',
        iconMainR: [],
        headSelectValue: sessionStorage.getItem('headSelectValue') || '查企业',
        headSearchPlaceholder: sessionStorage.getItem('headSearchPlaceholder') || '请输入企业名称、法定代表人等关键词',
        enterprise: {
            tableData: [],
            total: 0
        },
        patent: {
            tableData: [],
            total: 0
        },
        technical: {
            tableData: [],
            total: 0
        },
        inventor: {
            tableData: [],
            total: 0
        },
        globalContainer: null,
        topSearchValue: '',
        globalName: '',
        globalLogo: '',
        matchCurTab: sessionStorage.getItem('matchCurTab') || '',
        matchCurTipName: sessionStorage.getItem('matchCurTipName') || ''
    },
    getters: {
        getIsDetail(state) {
            return state.isDetail;
        },
        getCurrentPath(state) {
            return state.currentPath;
        },
        isLoading: state => state.loadingCount > 0
    },
    mutations: {
        setIsDetail: (state, data) => {
            state.isDetail = data;
        },
        setBreadcrumbs: (state, data) => {
            state.breadcrumbs = data;
        },
        setUpPath: (state, data) => {
            state.upPath = data;
        },
        setCurrentPath: (state, data) => {
            sessionStorage.setItem('currentPath', data);
            state.currentPath = data;
        },
        setCurrentUser: (state, data) => {
            state.currentUser = data;
        },
        setSubName: (state, data) => {
            if (!isEmptyObject(data)) {
                for (let key in data) {
                    if (data.hasOwnProperty(key)) {
                        if (key === 'subName') {
                            state.subName = data[key]
                            sessionStorage.setItem('subName', data[key]);
                        }
                        if (key === 'subNameDetail') {
                            state.subNameDetail = data[key]
                            sessionStorage.setItem('subNameDetail', data[key]);
                        } else {
                            state.subNameDetail = null
                            sessionStorage.removeItem('subNameDetail');
                        }
                        if (key === 'subLastNameDetail') {
                            state.subLastNameDetail = data[key]
                            sessionStorage.setItem('subLastNameDetail', data[key]);
                        } else {
                            state.subLastNameDetail = null
                            sessionStorage.removeItem('subLastNameDetail');
                        }
                        if (key === 'subLastNameDetail2') {
                            state.subLastNameDetail2 = data[key]
                            sessionStorage.setItem('subLastNameDetail2', data[key]);
                        } else {
                            state.subLastNameDetail2 = null
                            sessionStorage.removeItem('subLastNameDetail2');
                        }
                    }
                }
            } else {
                sessionStorage.removeItem('subName');
                state.subName = null;
                sessionStorage.removeItem('subLastNameDetail');
                state.subLastNameDetail = null;
                sessionStorage.removeItem('subLastNameDetail2');
                state.subLastNameDetail2 = null;
            }
        },
        clearState(state) {
            state.isDetail = false;
            state.breadcrumbs = [];
            state.upPath = '';
            state.currentPath = '/home';
            state.currentUser = 'admin';
            state.subName = '';
            state.subNameDetail = '';
            state.isUser = '0'
            state.isHideSearch = '0'
        },
        setSwitchSearch: (state, data) => {
            sessionStorage.setItem('isHideSearch', data);
            state.isHideSearch = data;
        },
        setHomeTopActive: (state, data) => {
            if (data === 'clear') {
                sessionStorage.removeItem('homeTopActive');
                state.homeTopActive = '';
            } else {
                sessionStorage.setItem('homeTopActive', data);
                state.homeTopActive = data;
            }
        },
        setSearchWord: (state, data) => {
            state.searchWord = data;
        },
        incrementLoading(state) {
            state.loadingCount++;
        },
        decrementLoading(state) {
            if (state.loadingCount > 0) {
                state.loadingCount--;
            }
        },
        setUserPath: (state, data) => {
            sessionStorage.setItem('userPath', data);
            state.userPath = data
        },
        setHeadSelectValue: (state, data) => {
            let key = '';
            if (data === '企业') {
                key = '法定代表人'
            }
            if (data === '专利') {
                key = '专利号'
            }
            if (data === '发明人') {
                key = '职称'
            }
            if (data === '技术') {
                key = '技术'
            }
            let sd = `查${data}`
            let pd = `请输入${data}名称、${key}等关键词`
            state.headSelectValue = sd;
            state.headSearchPlaceholder = pd
            sessionStorage.setItem('headSelectValue', sd);
            sessionStorage.setItem('headSearchPlaceholder', pd);
        },
        setEnterprise: (state, data) => {
            state.enterprise.tableData = data.data || [];
            state.enterprise.total = data.total || 0;
        },
        setPatent: (state, data) => {
            state.patent.tableData = data.data || [];
            state.patent.total = data.total || 0;
        },
        setTechnical: (state, data) => {
            state.technical.tableData = data.data || [];
            state.technical.total = data.total || 0;
        },
        setInventor: (state, data) => {
            state.inventor.tableData = data.data || [];
            state.inventor.total = data.total || 0;
        },
        setGlobalContainer: (state, data) => {
            state.globalContainer = data;
        },
        setTopSearchValue: (state, data) => {
            state.topSearchValue = data;
        },
        setGlobalName: (state, data) => {
            if (!data || Object.keys(data).length === 0) {
                state.globalName = ''
                state.globalLogo = ''
            } else {
                state.globalName = data.deptName
                state.globalLogo = data.logo
            }
        },
        setMatchCurTab: (state, data) => {
            let curTip;
            if (data === '专利匹配企业') {
                curTip = '企业'
            }
            if (data === '企业匹配专利') {
                curTip = '专利'
            }
            if (data === '智能推荐专利') {
                curTip = '专利'
            }
            if (data === '智能推荐专家') {
                curTip = '专家'
            }
            state.matchCurTab = data
            state.matchCurTipName = curTip
            sessionStorage.setItem('matchCurTab', data)
            sessionStorage.setItem('matchCurTipName', curTip)
        }
    },
    actions: {
        async logOut({commit}) {
            const {code} = await logout()
            if(code==200){
                localStorage.clear()
                sessionStorage.clear()

                commit('clearState');
            }
        },
    },
});
