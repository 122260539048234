import {ajax} from './ajax';

import {BASE_URL} from './baseUrl';

const getTechnicalPage = (
    {
        pageNum,
        pageSize,
        ...params
    }
) => ajax(BASE_URL + `/enterprise/skillDemand/list?pageNum=${pageNum}&pageSize=${pageSize}`, params, 'POST');

const getEnDetailById = (id) => ajax(BASE_URL + `/enterprise/skillDemand/${id}`);

export {
    getTechnicalPage,
    getEnDetailById
};
