import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import App from './App.vue';
import 'element-plus/dist/index.css';
import '../src/server/indexMock';
import zhCn from 'element-plus/dist/locale/zh-cn.mjs';
import router from './router';
import store from './store';
import './global.css';
import './untils/rem.js' // 缩放自适应
import 'animate.css';

createApp(App).use(store).use(router).use(ElementPlus, { locale: zhCn }).mount('#app');
