<template>
  <div class="g_head">
    <div class="g_head_l">
      <el-image class="head_logo1" :src="logoUrl" fit="fill"/>
      <el-image
          class="head_logo"
          v-if="globalStore.state.globalLogo !==''"
          :src="globalStore.state.globalLogo"
          fit="fill"/>
      <div class="sys_name" v-if="globalStore.state.globalName !== ''">
        {{ globalStore.state.globalName }}专利成果转化智能推荐系统
      </div>
    </div>
    <div class="g_head_r">
      <div class="avatar ellipsis_text">
        <el-dropdown>
         <span class="avatar_box">
            <el-avatar
                style="width: 3.21rem;height: 3.21rem;margin-right: 0.71rem"
                v-if="globalStore.state.globalLogo !==''"
                :src="globalStore.state.globalLogo"/>
            您好，<span>{{ globalStore.state.currentUser }}</span>
          </span>
          <template #dropdown>
            <el-dropdown-menu class="avatar_box_item">
              <el-dropdown-item @click="onChangeUser('1','user')" class="avatar_box_itemli">
                <el-icon>
                  <Setting/>
                </el-icon>
                <span class="span">用户设置</span>
              </el-dropdown-item>
              <el-dropdown-item @click="onChangeUser('2','user')" class="avatar_box_itemli">
                <el-icon>
                  <Clock/>
                </el-icon>
                <span class="span">历史记录</span>
              </el-dropdown-item>
              <el-dropdown-item @click="onChangeUser('3','user')" class="avatar_box_itemli">
                <el-icon>
                  <Star/>
                </el-icon>
                <span class="span">我的收藏</span>
              </el-dropdown-item>
              <el-dropdown-item @click="onChangeUser('4','user')" class="avatar_box_itemli">
                <el-icon>
                  <Document/>
                </el-icon>
                <span class="fs16">我的需求</span>
              </el-dropdown-item>
              <el-dropdown-item @click="goOut" class="avatar_box_itemli">
                <el-icon>
                  <SwitchButton/>
                </el-icon>
                <span class="span">退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from 'vue-router';
import {SwitchButton, Setting, Clock, Star, Document} from '@element-plus/icons-vue';
import {useStore} from 'vuex';
import logo from '@/assets/kzb/logo-kzb.png';
import logo2 from '@/assets/kzb/logo-zbdx.png';
import {goLogin} from "@/untils";

const globalStore = useStore();
const routers = useRouter();
const logoUrl = logo;
const logoUrl2 = logo2;

const onChangeUser = (type, type2) => {
  let name;
  let path;
  if (type === '1') {
    name = '用户设置'
    path = '/personal'
  }
  if (type === '2') {
    name = '历史记录'
    path = '/history'
  }
  if (type === '3') {
    name = '我的收藏'
    path = '/favorites'
  }
  if(type === '4'){
    path = '/myNeed'
  }
  routers.push(path);
};
const goOut = () => {
  globalStore.dispatch('logOut').then(() => {
    goLogin();
  });
};
</script>

<style scoped lang="less">
.g_head {
  width: 100%;
  height: 85px; //85
  background: #FFFFFF;
  box-shadow: 0 3px 6px 0 rgba(128, 128, 128, 0.2);
  padding: 0 50px 0 30px; //70 30
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  z-index: 999;
}

.g_head_l, .g_head_r {
  display: flex;
  align-items: center;
}

.g_head_l {
  .head_logo1 {
    width: 163px; //164
    height: 49px; //50
    box-sizing: border-box;
  }

  .head_logo {
    width: 50px; //50
    height: 50px; //50
    margin: 0 0.86rem 0 2.85rem;
    box-sizing: border-box;

    img {
      width: 50px; //50
    }
  }

  .sys_name {
    font-weight: bold;
    font-size: 20px; //20
    //letter-spacing: 1px;
  }

  //.sys_name:hover {
  //  cursor: pointer;
  //}
}

.input-with-select {
  width: 600px; //660
  height: 45px; //
  box-sizing: border-box;
  //min-width: 460px;
  font-size: 1rem;

  /deep/ .is-focus {
    box-shadow: none !important;
    border: none !important;
  }

  /deep/ .is-focused {
    box-shadow: none !important;
    border: none !important;
  }
}

.g_head_r {
  font-size: 1rem;

  .avatar {
    display: flex;
    align-items: center;

    /deep/ .el-dropdown, /deep/ .el-dropdown-menu {
      font-size: inherit;
    }

    .avatar_box {
      display: flex;
      align-items: center;
      font-size: 1.14rem;
    }

    .avatar_box:hover {
      cursor: pointer;
      color: #2D6CFF;
    }
  }

  /deep/ .el-input-group {
    border: 1px solid #2D6CFF;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    background: #fff;
  }

  /deep/ .el-input-group--append > .el-input__wrapper,
  /deep/ .el-input__wrapper {
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  /deep/ .el-input-group__prepend,
  /deep/ .el-input-group--prepend .el-input-group__prepend .el-select .el-select__wrapper {
    background-color: transparent;
    box-shadow: none;
    font-size: 1rem;
  }

  /deep/ .el-input-group__append {
    width: 7.85rem; //110
    box-sizing: border-box;
    background-color: #2D6CFF;
    border-radius: 0.71rem;
    margin-right: 1px;

    button {
      display: flex;
      align-items: center;
      color: white;
    }
  }
}

.home_nav {
  width: 160px; //160
  height: 45px; //44
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 0.71rem;
  border: 1px solid #E9E9E9;
  margin: 0 3.57rem;
  //min-width: 130px;

  /deep/ .el-dropdown {
    width: 100%;
    height: 100%;
  }

  .el-dropdown-link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.71rem;
    color: #171B1E;
    font-size: 1.14rem
  }

  .el-dropdown-link:hover {
    background: #2D6CFF;
    color: white;
    transition: all .3s;
  }

  .home_nav_tip {
    font-weight: bold;
    padding: 0 0.71rem;
  }
}

.drop_menu_box {
  //padding: 0.71rem 1.42rem;
}

/deep/ .el-dropdown:focus {
  outline: none;
}

/deep/ .el-dropdown:focus-visible {
  outline: none;
}

/deep/ .el-dropdown-menu__item {
  line-height: 3.43rem;
  padding: 0 1.5rem;
}

.drop_item {
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.71rem;
  margin: 1px 0;
}

.drop_item:hover {
  background: #2D6CFF;
  color: white;
  transition: all .3s;
  border-radius: 0.71rem;
}

.drop_item_active {
  background: #2D6CFF;
  color: white;
  border-radius: 0.71rem;
}

.fs16{
  font-size: 16px!important;
}

.avatar_box_item{
  padding: 0!important;

  :deep(span){
    font-size: 16px;
  }
}
</style>
