<template>
  <div class="info_card_box" id="infoCard">
    <div class="info_card">
      <div class="ic_top">
        <Breadcrumb/>
        <div class="ic_top_r">
          <el-button v-show="showExport" class="ic_top_rbtn1" @click="onExport">
            <el-icon v-if="!exportLoading">
              <Upload/>
            </el-icon>
            <el-icon class="is-loading" v-else style="color: #2D6CFF">
              <Loading />
            </el-icon>
            {{ exportName }}
          </el-button>
          <el-button class="ic_top_rbtn2" @click="onBack">
            <el-icon>
              <ArrowLeft/>
            </el-icon>
            返回
          </el-button>
        </div>
      </div>
      <slot name="info"></slot>
    </div>
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router';
import {defineProps, defineEmits,ref} from 'vue';
import {ArrowLeft, Loading, Upload} from '@element-plus/icons-vue';
import Breadcrumb from '@/components/Breadcrumb.vue';
import {postRequest} from "@/server/enterprise";
import {downloadLocalTemplate} from "@/untils";
import {ElMessage} from "element-plus";

const toRoute = useRouter();
const exportLoading = ref(false)

const emit = defineEmits(['handleExport'])

const props = defineProps({
  showExport: Boolean,
  exportName: String,
  exportPath: String,
  exportParams: Object,
  downImg: String
});

const onBack = () => {
  toRoute.back();
};
const onExport = async () => {
  if(props.exportPath){
    try{
      exportLoading.value = true
      const {code,msg} = await postRequest(props.exportPath,{college: localStorage.getItem('school_deptName'),applicationTrend:props.downImg || null,...props.exportParams})
      if(code==200){
        downloadLocalTemplate(msg)
        exportLoading.value = false
      }else{
        ElMessage.error(msg)
        exportLoading.value = false
      }
    }catch (e) {
      console.log(e)
    }
  }else{
    emit('handleExport', props.exportName)
  }
};

</script>
<style scoped lang="less">
.info_card_box {
  //height: 100%;
  //overflow-y: auto;
  background: #EBEBEB;
}

.info_card {
  width: 100%;
  min-height: calc(100vh - 85px);
  //background: #F3F3F6;
  box-sizing: border-box;
  padding: 30px; //30

  .ic_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    height: 60px;
    border-radius: 10px 10px 0 0;
    color: #666666;
    border-bottom: 1px solid #E9E9E9;
    padding: 0 30px;
    box-sizing: border-box;

    /deep/ .el-breadcrumb__separator {
      margin: 0 13px;
    }
  }

  .ic_top_r {
    display: flex;
  }

  .ic_top_rbtn1 {
    padding: 0 1.07rem; //15
    height: 2.29rem;
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E9E9E9;
    font-size: 1rem;
  }

  .ic_top_rbtn2 {
    width: 5.14rem; //72
    height: 2.29rem; //32
    background: #FFFFFF;
    border-radius: 2px;
    border: 1px solid #E9E9E9;
    font-size: 1rem;
  }
}

//@media screen and(max-width: 1600px) {
//  .info_card {
//    padding: 25px;
//  }
//}
//
//@media screen and(max-width: 1440px) {
//  .info_card {
//    padding: 20px;
//  }
//}
//
//@media screen and(max-width: 1366px) {
//  .info_card {
//    padding: 15px;
//  }
//}
</style>
