import {ajax} from './ajax';

import {BASE_URL} from './baseUrl';

/*
技术领域 technosphere
所属学院 college
资质荣誉 credential
发明人列表 /prod-api/patents/inventor/list
详情 /prod-api/patents/inventor/{id}
合作企业 /patents/inventor/selectEnterprise
专利产出 /patents/inventor/selectPatentsOut
* */

const getInventorPage = (
    {
        pageNum,
        pageSize,
        ...params
    }
) => ajax(BASE_URL + `/patents/inventor/list?pageNum=${pageNum}&pageSize=${pageSize}`, params, 'POST');
const getPartnersById = (id, pageNum, pageSize) => ajax(BASE_URL + `/patents/inventor/selectPartener?pageNum=${pageNum}&pageSize=${pageSize}&id=${id}`);
const getCooperativeEnterprisesById = (id, pageNum, pageSize) => ajax(BASE_URL + `/patents/inventor/selectEnterprise?pageNum=${pageNum}&pageSize=${pageSize}&id=${id}`);
const getPartnerOutById = (id, pageNum, pageSize,status,flsj) => ajax(BASE_URL + `/patents/inventor/selectPatentsOut?pageNum=${pageNum}&pageSize=${pageSize}&id=${id}&status=${status}&flsj=${flsj}`);

const getInventorDetail = (id) => ajax(BASE_URL + `/patents/inventor/${id}`);

export {
    getInventorPage,
    getInventorDetail,
    getPartnersById,
    getCooperativeEnterprisesById,
    getPartnerOutById
};
