import {ajax, ajaxUpload} from './ajax';

import {BASE_URL} from './baseUrl';

/*
列表 collection/collection/list
用户收藏 /collection/collection
取消收藏 /collection/collection/cancel
// 发明人 inventor,企业 enterprise, 专利 patent, 技术需求 requirements;

浏览记录 /tUserBrowse/list

用户信息 /getinfo

* */

const getCollection = (pageNum, pageSize,uId) => ajax(`${BASE_URL}/collection/collection/list?pageNum=${pageNum}&pageSize=${pageSize}&uId=${uId}`);
const addCollection = (params) => ajax(`${BASE_URL}/collection/collection`, params, 'POST');
const cancelCollection = (params) => ajax(`${BASE_URL}/collection/collection/cancel`, params, 'POST');
// const cancelCollection = (params) => ajax(`${BASE_URL}//collection/collection/ `, params, 'POST');
const getBrowseList = (params) => ajax(`${BASE_URL}/tUserBrowse/list`, params, 'GET');
const getUserInfo = () => ajax(`${BASE_URL}/getInfo`);

export {
    getCollection,
    addCollection,
    cancelCollection,
    getBrowseList,
    getUserInfo
};
