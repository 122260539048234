import {ajax} from './ajax';

import {BASE_URL} from './baseUrl';

/*
专利 patents/patents/list
/patents/patents/1
/patents/statictis/queryByzlsqqsAndYear
/patents/statictis/queryInventorTop
/patents/statictis/queryByzlsqlxAndYear
/patents/statictis/queryByflsjAndYear
合作伙伴 /patents/inventor/selectPartener

导出 /prod-api/enterprise/patent/export

大屏
专利类型 /prod-api/patents/patents/zltype
专利状态 /prod-api/patents/patents/zlzt
排名分析==》申请量 /prod-api/patents/patents/zlPmfx
排名分析==》授权量 /prod-api/patents/patents/zlPmfxsql
发明人排名 /prod-api/patents/patents/zlfmrpm
转化地区 /prod-api/patents/patents/zlzhdq
专利词云
转化方式 /prod-api/patents/patents/zlzhfs
转化地区 省份 /prod-api/patents/patents/zlzhdqsf
转化地区 城市 /prod-api/patents/patents/zlzhdqcs

趋势分析==》有效专利量 /prod-api/patents/patents/zlqsfx1
趋势分析==》专利转化量 /prod-api/patents/patents/zlqsfx2
趋势分析==》专利产业转化率 /prod-api/patents/patents/zlqsfx3

专利导航图谱
专利申请趋势 /prod-api/patents/statictis/queryByzlsqqsAndYear
发明人top10/活跃发明人 /prod-api/patents/statictis/queryInventorTop
专利申请类型 /prod-api/patents/statictis/queryByzlsqlxAndYear
法律事件 /prod-api/patents/statictis/queryByflsjAndYear
专利状态 patents/statictis/queryByzlztAndYear
专利列表 /prod-api/patents/patents/list
* */
const getPatentPage = (
    {
        pageNum,
        pageSize,
        ...params
    }
) => ajax(BASE_URL + `/patents/patents/list?pageNum=${pageNum}&pageSize=${pageSize}`, Object.assign({},params), 'POST');
const getPatentExport = () => ajax(BASE_URL + `/enterprise/patent/export`, {}, 'POST');
const getDetailById = (id) => ajax(BASE_URL + `/patents/patents/${id}`);
const getPatentTypes = (year, school) => ajax(BASE_URL + `/patents/patents/zltype?y=${year}&school=${school}`);
const getPatentStatus = (year, school) => ajax(BASE_URL + `/patents/patents/zlzt?y=${year}&school=${school}`);
const getPatentSort = (year, school) => ajax(BASE_URL + `/patents/patents/zlfmrpm?y=${year}&school=${school}`);
const getPatentSortAnalysis1 = (year, school) => ajax(BASE_URL + `/patents/patents/zlPmfx?y=${year}&school=${school}`);
const getPatentSortAnalysis2 = (year, school) => ajax(BASE_URL + `/patents/patents/zlPmfxsql?y=${year}&school=${school}`);
const getPatentArea = (year, school) => ajax(BASE_URL + `/patents/patents/zlzhdq?y=${year}&school=${school}`);
const getPatentConversionSF = (year, school) => ajax(BASE_URL + `/patents/patents/zlzhdqsf?y=${year}&school=${school}`);
const getPatentConversionCS = (year, school) => ajax(BASE_URL + `/patents/patents/zlzhdqcs?y=${year}&school=${school}`);
const getPatentConversionType = (year, school) => ajax(BASE_URL + `/patents/patents/zlzhfs?y=${year}&school=${school}`);
const getTrendAnalysis1 = (year, school) => ajax(BASE_URL + `/patents/patents/zlqsfx1?y=${year}&school=${school}`);
const getTrendAnalysis2 = (year, school) => ajax(BASE_URL + `/patents/patents/zlqsfx2?y=${year}&school=${school}`);
const getTrendAnalysis3 = (year, school) => ajax(BASE_URL + `/patents/patents/zlqsfx3?y=${year}&school=${school}`);
const getGraph1 = (year,gmjjfl,school_deptName,id) => ajax(BASE_URL + `/patents/statictis/queryByzlsqqsAndYear?year=${year}&gmjjfl=${gmjjfl}&college=${school_deptName}&id=${id}`);
const getGraph2 = (gmjjfl,school_deptName,id) => ajax(BASE_URL + `/patents/statictis/queryInventorTop?gmjjfl=${gmjjfl}&college=${school_deptName}&id=${id}`);
const getGraph3 = (year,gmjjfl,school_deptName,id) => ajax(BASE_URL + `/patents/statictis/queryByzlsqlxAndYear?year=${year}&gmjjfl=${gmjjfl}&college=${school_deptName}&id=${id}`);
const getGraph4 = (year,gmjjfl,school_deptName,id) => ajax(BASE_URL + `/patents/statictis/queryByflsjAndYear?year=${year}&gmjjfl=${gmjjfl}&college=${school_deptName}&id=${id}`);
const getGraph5 = (year, school,gmjjfl,school_deptName,id) => ajax(BASE_URL + `/patents/statictis/queryByzlztAndYear?year=${year}&school=${school}&gmjjfl=${gmjjfl}&college=${school_deptName}&id=${id}`);
const getGraph6 = (data) => ajax(BASE_URL + `/patents/patents/list?pageNum=${data.pageNum}&pageSize=${data.pageSize}&gmjjfl=${data.gmjjfl}`, data, 'POST');
export const getGraphList = (data) => ajax(BASE_URL + `/patents/patents/queryPatentsByChain`, data);
export const queryChain = (data) => ajax(BASE_URL + `/patents/patents/queryChain`);

export {
    getPatentPage,
    getDetailById,
    getPatentTypes,
    getPatentStatus,
    getPatentSort,
    getPatentSortAnalysis1,
    getPatentSortAnalysis2,
    getPatentArea,
    getPatentExport,
    getPatentConversionSF,
    getPatentConversionCS,
    getPatentConversionType,
    getTrendAnalysis1,
    getTrendAnalysis2,
    getTrendAnalysis3,
    getGraph1,
    getGraph2,
    getGraph3,
    getGraph4,
    getGraph5,
    getGraph6
};
