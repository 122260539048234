<template>
  <div ref="echartsContainer" class="chart_ref" :id="id" style="width: 100%;height: 100%"></div>
</template>

<script>
  import * as echarts from 'echarts';
  import 'echarts-gl';
  import 'echarts-wordcloud';
  import chinaJson from '@/jsonData/full2.json'; // 引入中国地图数据

  export default {
    props: {
      options: {
        type: Object,
        required: true
      },
      isMap: {
        type: Boolean,
        default: false
      },
      id: {
        type: String,
        default: 'echarts_id'
      }
    },
    data () {
      return {
        echartsInstance: null
      };
    },
    watch: {
      options: {
        deep: true,
        handler(newOptions) {
          if (this.echartsInstance) {
            this.echartsInstance.setOption(newOptions);
            this.handleResize()
          }
        }
      }
    },
    mounted () {
      this.initECharts();
      this.setOptions();
      window.addEventListener('resize', this.handleResize);
    },
    methods: {
      initECharts () {
        if (this.isMap) {
          echarts.registerMap('china', chinaJson);
          this.echartsInstance = echarts.init(this.$refs.echartsContainer)
        } else {
          this.echartsInstance = echarts.init(this.$refs.echartsContainer);
        }
      },
      setOptions () {
        this.echartsInstance.setOption(this.options);
      },
      handleResize () {
        if (this.echartsInstance) {
          this.echartsInstance.dispose();
          this.initECharts();
          this.setOptions();
        }
      }
    },
    // computed: {
    //   debouncedResize: debounce(function () {
    //     this.handleResize();
    //   }, 300), // 调整 debounce 的延迟时间，根据实际需要
    // },
    beforeUnmount () {
      window.removeEventListener('resize', this.handleResize);
      if (this.echartsInstance) {
        this.echartsInstance.dispose();
      }
    }
  };
</script>

<style scoped lang="less">
  .chart_ref {
    z-index: 101;
    //width: 100%;
    //height: 100%;

    /deep/ div{
      //width: 100%!important;
      //height: 100%!important;
    }
  }
</style>
