<template>
  <el-select
      :model="value"
      :placeholder="placeholder"
      ref="select"
      :multiple="multiple"
      :suffix-icon="CaretBottom"
      @change="handleChange"
      :style="{width:width,height:height,borderRadius:isRadius ? '10px' : '0px'}"
      clearable
  >
    <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
    ></el-option>
  </el-select>
</template>

<script setup>
import {CaretBottom} from '@element-plus/icons-vue';
import {defineProps, defineEmits} from 'vue';

defineProps({
  value: {
    type: [String, Number],
    default: ''
  },
  placeholder: {
    type: String,
    default: '请选择'
  },
  clearable: {
    type: Boolean,
    default: true
  },
  options: {
    type: Array,
    required: true
  },
  width: {
    type: String
  },
  height: {
    type: String,
    default: 'inherit'
  },
  isRadius: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['change'])
const handleChange = (value) => {
  emit('change', value)
}

</script>
<style scoped lang="less">
.el-select {
  border: 1px solid #E9E9E9;
  box-sizing: border-box;
  height: 100%;
}

/deep/ .el-select__wrapper {
  border-color: #E9E9E9;
  box-sizing: border-box;
  background-color: transparent;
}

/deep/ .is-near {
  flex-wrap: nowrap;
  overflow: hidden;
}

/deep/ .el-select__placeholder {
  overflow: visible;
}

/deep/ .el-tag {
  color: #0A1629;
}

/deep/ .el-tag .el-tag__close {
  color: #0A1629;
}
</style>
