import {ajax} from './ajax';

import {BASE_URL, TERRITORY_URL} from './baseUrl';

/*
企业科创能力榜单 enterprise/enterprise/queryStic enterpriseKCNL
企业技术合作榜单 enterprise/enterprise/queryTechCooperation
最新授权专利
最新转让专利
企业list
专利购买 /enterprise/enterprise/queryPatentPurchase
联合申请 /enterprise/enterprise/queryJointApplication
揭榜挂帅 /enterpriseOpenLeader/enterpriseOpenLeader/{id}
企业概况 /enterprise/enterprise/queryDetailById
研发概要 /enterprise/enterprise/queryCompanyDetailCnt

研发可持续性1 /enterprise/enterprise/queryZlsqnfqsOfYear
研发可持续性2 /enterprise/enterprise/queryLnzlsqsq
研发可持续性统计 /enterprise/enterprise/queryZlnum

专利结构分析
全部专利分布图 /enterprise/enterprise/queryAllByType
简单法律分布图 /enterprise/enterprise/queryAllByStatusNew
有效专利分布图 /enterprise/enterprise/queryYxzlByType

企业融资经历 /prod-api/enterprise/finance/list entId 对应companyId
企业对外投资 /enterprise/invest/list comId comId 对应companyId
企业股东信息 /enterprise/shareHolder/list  entId 对应companyId
企业主要人员 /enterprise/marjor/list entId 对应companyId

企业联想 /prod-api/enterprise/enterprise/queryName
* */
const enterpriseKCNL = (pageNum, pageSize) => ajax(BASE_URL + `/enterprise/enterprise/queryStic?pageNum=${pageNum}&pageSize=${pageSize}`);
const enterpriseJSHZBD = (pageNum, pageSize) => ajax(BASE_URL + `/enterprise/enterprise/queryTechCooperation?pageNum=${pageNum}&pageSize=${pageSize}`);
const getEnterprisePage = (
    {
        pageNum,
        pageSize,
        ...params
    }
) => ajax(BASE_URL + `/enterprise/enterprise/list?pageNum=${pageNum}&pageSize=${pageSize}`, params, 'POST');
const getDetailById = (id) => ajax(BASE_URL + `/enterprise/enterprise/${id}`);
const getKCNLPJById = (id, companyIndustry) => ajax(BASE_URL + `/enterprise/enterprise/querySticEvaluate?id=${id}&companyIndustry=${companyIndustry}`);
const getZLGMById = (id) => ajax(BASE_URL + `/enterprise/enterprise/queryPatentPurchase?id=${id}`);
const getLHSQById = (id) => ajax(BASE_URL + `/enterprise/enterprise/queryJointApplication?id=${id}`);
const getEnterpriseOverview = (id) => ajax(BASE_URL + `/enterprise/enterprise/queryDetailById?id=${id}`);
const getOpenLeaderInfo = (id) => ajax(BASE_URL + `/enterpriseOpenLeader/enterpriseOpenLeader/list?comId=${id}`);
const getRDSummary = (id) => ajax(BASE_URL + `/enterprise/enterprise/queryCompanyDetailCnt?companyId=${id}`);
const getSustainability1 = (id) => ajax(BASE_URL + `/enterprise/enterprise/queryZlsqnfqsOfYear?companyId=${id}`);
const getSustainability2 = (id) => ajax(BASE_URL + `/enterprise/enterprise/queryLnzlsqsq?companyId=${id}`);
const getSustainability3 = (id) => ajax(BASE_URL + `/enterprise/enterprise/queryZlnum?companyId=${id}`);
const getZLAllType = (id) => ajax(BASE_URL + `/enterprise/enterprise/queryAllByType?companyId=${id}`);
const getZLAllType2 = (id) => ajax(BASE_URL + `/enterprise/enterprise/queryAllByStatusNew?companyId=${id}`);
const getZLAllType3 = (id) => ajax(BASE_URL + `/enterprise/enterprise/queryYxzlByType?companyId=${id}`);
const enterpriseRZJL = ({
                            pageNum,
                            pageSize,
                            ...params
                        }) => ajax(BASE_URL + `/enterprise/finance/list?pageNum=${pageNum}&pageSize=${pageSize}&entId=${params.entId}`, params, 'POST');
const enterpriseDWTZ = (id, pageNum, pageSize,) => ajax(BASE_URL + `/enterprise/invest/list?comId=${id}&pageNum=${pageNum}&pageSize=${pageSize}`);
const enterpriseZYRY = ({
                            pageNum,
                            pageSize,
                            ...params
                        }) => ajax(BASE_URL + `/enterprise/marjor/list?pageNum=${pageNum}&pageSize=${pageSize}&entId=${params.entId}`, params, 'POST');
const enterpriseGDXX = ({
                            pageNum,
                            pageSize,
                            ...params
                        }) => ajax(BASE_URL + `/enterprise/shareHolder/list?pageNum=${pageNum}&pageSize=${pageSize}&entId=${params.entId}`, params, 'POST');
const getEnterpriseAuto = (name) => ajax(BASE_URL + `/enterprise/enterprise/queryName?name=${name}`);

// 企业技术领域布局接口
const getEnterpriseTechnology = (id) => ajax(TERRITORY_URL + `/cloud/enterpriseTechnology?ent_id=${id}`,{},'POST')

// 专利技术领域
const getPatentTechnology = (id) => ajax(TERRITORY_URL + `/cloud/patentTechnology?patent_id=${id}`,{},'POST')

// 专利应用领域
export const getPatentAppArea = (id) => ajax(TERRITORY_URL + `/cloud/patentAppArea?patent_id=${id}`,{},'POST')


// 院校专利成果转化仪表盘专利词云
export const getPatentCollege = (college) => ajax(TERRITORY_URL + `/cloud/patentCollege?college=${college}`,{college},'POST')

// 科技成果转化地图
export const getPatentHotArea = (id) => ajax(TERRITORY_URL + `/cloud/patentHotArea?patent_id=${id}`,{},'POST')

// 企业匹配专利
export const getEnterpriseRecommandPatent = (data) => ajax(TERRITORY_URL + `/recommend/enterpriseRecommandPatent`,data,'POST')

// 专利匹配企业
export const getPatentRecommandEnterprise = (data) => ajax(TERRITORY_URL + `/recommend/patentRecommandEnterprise`,data,'POST')

// 发明人画像
export const getDrewInventor = (data) => ajax(TERRITORY_URL + `/cloud/drewInventor`,data,'POST')

// 专利匹配企业导出
export const exportPatentRecommandEnterprise = TERRITORY_URL + '/recommend/exportPatentRecommandEnterprise'

// 企业匹配专利导出
export const exportExterpriseRecommandPatent = TERRITORY_URL + '/recommend/exportExterpriseRecommandPatent'


// 技术需求相互推荐
export const getSkillRecommendSkill = (data) => ajax(TERRITORY_URL + `/recommend/skillRecommendSkill`,data,'POST')

// get方法
export const getRequest = (url, data) => ajax(BASE_URL + url,data)

// post方法
export const postRequest = (url,data) => ajax(BASE_URL + url,data,'POST')

// put方法
export const putRequest = (url,data) => ajax(BASE_URL + url,data,'PUT')

// DELETE方法
export const deleteRequest = (url,data) => ajax(BASE_URL + url,data,'DELETE')

// cloud下的post方法
export const postRequestCloud = (url,data) => ajax(TERRITORY_URL + url, data,'POST')

export {
    enterpriseKCNL,
    enterpriseJSHZBD,
    getEnterprisePage,
    getDetailById,
    getKCNLPJById,
    getZLGMById,
    getLHSQById,
    getOpenLeaderInfo,
    getEnterpriseOverview,
    getRDSummary,
    getSustainability1,
    getSustainability2,
    getZLAllType,
    getZLAllType2,
    getZLAllType3,
    getSustainability3,
    enterpriseRZJL,
    enterpriseDWTZ,
    enterpriseZYRY,
    enterpriseGDXX,
    getEnterpriseAuto,
    getPatentTechnology,
    getEnterpriseTechnology
};
