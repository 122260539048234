<template>
  <div>
    <div class="export_page2_title">可视化分析</div>
    <div class="page_sub_title">专利申请趋势</div>
    <div v-if="chart1" :style="setHeight('chart')">
      <EchartTemp :options="state.options1"></EchartTemp>
    </div>
    <div class="page_sub_title">发明人top10</div>
    <div v-if="state.options2.series" :style="setHeight('chart')">
      <EchartTemp :options="state.options2"></EchartTemp>
    </div>
    <el-row :gutter="20">
      <el-col :span="8">
        <div class="line">
          <div class="top">
            <div class="page_sub_title">专利申请类型</div>
          </div>
          <div v-if="state.options3.series" :style="setHeight('chart')">
            <EchartTemp :options="state.options3"></EchartTemp>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="line">
          <div class="top">
            <div class="page_sub_title">法律事件</div>
          </div>
          <div v-if="state.options4.series" :style="setHeight('chart')">
            <EchartTemp :options="state.options4"></EchartTemp>
          </div>
        </div>
      </el-col>
      <el-col :span="8">
        <div class="line">
          <div class="top">
            <div class="page_sub_title">专利状态</div>
          </div>
          <div v-if="isShow5" :style="setHeight('chart')">
            <EchartTemp :options="state.options5"></EchartTemp>
          </div>
          <AEmpty v-else></AEmpty>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from 'vue';
import EchartTemp from '@/components/EchartTemp.vue';
import {options1, options2, options3, options4, options5} from '../patentMapGraph/chartsOptions';
import {
  getGraph1,
  getGraph2,
  getGraph3,
  getGraph4,
  getGraph5,
} from '@/server/patent';
import {groupByName} from "@/untils";
import AEmpty from "@/components/AEmpty.vue";

const chart1 = ref(false)

const isShow5 = ref(false)
const state = reactive({
  tableDataTotal: 0,
  tableData: [],
  options1: {},
  options2: {},
  options3: {},
  options4: {},
  options5: {},
  gmjjfl: '高端装备制造'
});
const setHeight = (type) => {
  return {
    height: `370px`
  };
};

const initData1 = async (year) => {
  const res1 = await getGraph1(year, state.gmjjfl,localStorage.getItem('school_deptName') || '')
  if (res1.code === 200 && res1.data) {
    const {data} = res1
    const years = [...new Set(data.map(item => item.year))]
    const types = [...new Set(data.map(item => item.zlType))]
    state.options1 = {...options1};
    const arr = groupByName(data, 'zlType')
    const sData = arr.map(item => {
      if (item.length > 0) {
        return {
          name: item[0].zlType,
          type: 'line',
          symbol: 'none',
          lineStyle: {
            width: 2
          },
          data: item.map(item => item.num)
        };
      }
    });
    state.options1.xAxis[0].data = years;
    state.options1.legend.data = types;
    state.options1.series = [...sData];
    chart1.value = true
  }
}
const initData2 = async () => {
  const res2 = await getGraph2(state.gmjjfl,localStorage.getItem('school_deptName') || '')
  if (res2.code === 200 && res2.data) {
    const {data} = res2
    state.options2 = {...options2};
    state.options2.yAxis[0].data = data.map(item => item.inventor);
    state.options2.yAxis[1].data = data.map(item => item.num);
    state.options2.series[0].data = data.map(item => item.num);
  }
}
const initData3 = async (year) => {
  const res3 = await getGraph3(year, state.gmjjfl,localStorage.getItem('school_deptName') || '')
  if (res3.code === 200 && res3.data) {
    const {data} = res3
    state.options3 = {...options3};
    state.options3.series[0].data = data.map(item => {
      return {
        name: item.zlType,
        value: item.zlNum
      };
    });
  }
}
const initData4 = async (year) => {
  const res4 = await getGraph4(year, state.gmjjfl,localStorage.getItem('school_deptName') || '')
  if (res4.code === 200 && res4.data) {
    const {data} = res4
    state.options4 = {...options4};
    state.options4.series[0].data = data.map(item => {
      return {
        name: item.flsj,
        value: item.zlNum
      };
    });
  }
}
const initData5 = async (year, school) => {
  const res5 = await getGraph5(year, school, state.gmjjfl,localStorage.getItem('school_deptName') || '')
  if (res5.code === 200 && res5.data) {
    const {data} = res5
    if (data.length > 0) {
      state.options5 = {...options5};
      state.options5.series[0].data = data.map(item => {
        return {
          name: item.zlStatus,
          value: item.zlNum
        };
      });
    }
    isShow5.value = data.length > 0
  }
};

onMounted(() => {
  initData1('9')
  initData2()
  initData3('3')
  initData4('3')
  initData5('9', '')
});

</script>

<style scoped lang="less">
.graph_map {
  display: flex;
  justify-content: space-between;
  margin-top: 1.42rem;

  .right {
    flex: 1;
    height: 100%;
    overflow-y: auto;

    .line {
      background: #FFFFFF;
      border-radius: 10px;
      border: 1px solid #E9E9E9;
      height: 414px;
      padding: 1.42rem 0;
      box-sizing: border-box;
    }

    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      padding: 0 1.42rem;

      .title {
        font-weight: bold;
        font-size: 1.1em;
        color: #1A202C;
      }
    }
  }
}
</style>
