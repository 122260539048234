<template>
  <div v-if="globalStore.getters.isLoading" class="loading-overlay">
    <el-icon class="is-loading" size="20">
      <Loading/>
    </el-icon>
    <span class="mt10">系统正在加载中~</span>
  </div>
  <router-view/>
</template>
<script setup>
import {onMounted, onUnmounted, ref} from "vue";
import {useStore} from 'vuex';
import {Loading} from "@element-plus/icons-vue";

const globalStore = useStore();

const gap_time = ref(0)
const beforeUnload_time = ref(0)

const beforeunloadHandler = (event) => {
  beforeUnload_time.value = new Date().getTime();
}
const unloadHandler = (event) => {
  gap_time.value = new Date().getTime() - beforeUnload_time.value;
  //判断是窗口关闭还是刷新 毫秒数判断 网上大部分写的是5
  if (gap_time.value <= 5) {
    globalStore.dispatch('logOut').then(() => {
      localStorage.clear()
    })
  }
}

onMounted(() => {
// 监听浏览器关闭
//   window.addEventListener("beforeunload", () => beforeunloadHandler());
//   window.addEventListener("unload", () => unloadHandler());
//   window.addEventListener('beforeunload', e => {
//     globalStore.dispatch('logOut').then(() => {
//       localStorage.clear()
//     })
//   })
})


onUnmounted(() => {
  // 移除监听
  // window.removeEventListener("beforeunload", () => beforeunloadHandler());
  // window.removeEventListener("unload", () => unloadHandler());
})

</script>
<style>
#app {
  font-family: Microsoft YaHei, Microsoft YaHei;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  color: #171B1E;
  box-sizing: border-box;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  font-size: 16px;

  span {
    letter-spacing: 2px;
    opacity: .7;
    margin-top: 10px;
  }
}
</style>
